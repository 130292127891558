import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import { retry, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  baseUrl:string;
  basicUrl:string;
  logoUrl:string;

  private online = new BehaviorSubject<boolean>(true);
  private googleUser = new BehaviorSubject<boolean>(false);
  private _isAdmin = new BehaviorSubject<boolean>(false);
  private _isPartner = new BehaviorSubject<boolean>(false);
  private _isCapturer = new BehaviorSubject<boolean>(false);
  private _isOwner = new BehaviorSubject<boolean>(false);
  private _isSuper = new BehaviorSubject<boolean>(false);
  private avatarName = new BehaviorSubject<string>('none');
  private hasAvatar = new BehaviorSubject<boolean>(false);
  private avatarInitials = new BehaviorSubject<string>('');
  private verifUser = new BehaviorSubject<any>({u:'',p:''});
  private setUser = new BehaviorSubject<any>({userid:-99});
  private registered = new BehaviorSubject<boolean>(false);
  private searchParameters = new BehaviorSubject<any>({s:'',l:''});
  private shownav = new BehaviorSubject<boolean>(true);
  avatar = this.avatarName.asObservable();
  avatarpresent = this.hasAvatar.asObservable();
  initials = this.avatarInitials.asObservable();
  verifuser = this.verifUser.asObservable();
  setuser = this.setUser.asObservable();
  isOnline = this.online.asObservable();
  searchParams = this.searchParameters.asObservable();
  registereduser = this.registered.asObservable();
  _shownav = this.shownav.asObservable();

  constructor(public http:HttpClient) {
    console.log('Online='+JSON.stringify(this.online.value))
    if(this.online.value){
      this.baseUrl = 'https://api.profila.co.za/api';
      this.basicUrl='https://api.profila.co.za';
      this.logoUrl="https://api.profila.co.za/storage/app/public/";
    }else{
      this.baseUrl = 'http://localhost:8000/api'; 
      this.basicUrl='http://localhost:8000';
      this.logoUrl="http://localhost:8000/storage/";
    }
    //this.baseUrl = 'http://localhost:8000/api';
  }

  setNav(b) {
    this.shownav.next(b);
   }

  setUserdetails(u) {
    this.setUser.next(u);
   }

   setVerifaccount(u) {
    this.verifUser.next(u);
   }

   setParameters(p) {
    this.searchParameters.next(p);
   }

  setRegistered(r) {
    this.registered.next(r);
   }

  addBusiness(info)
  {
    return this.http.post(this.baseUrl+'/addBusiness', info)
     .pipe(retry(0),map(res => res));
  }

  addBusiness2(info)
  {
    return this.http.post(this.baseUrl+'/addBusiness2', info)
     .pipe(retry(0),map(res => res));
  }

  addOrder(id, info)
  {
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/addWorks/' + id, info, {headers})
    return this.http.post(this.baseUrl+'/addOrder/' + id, info)
     .pipe(retry(0),map(res => res));
  }

  addWorks(id, info)
  {
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/addWorks/' + id, info, {headers})
    return this.http.post(this.baseUrl+'/addWorks/' + id, info)
     .pipe(retry(0),map(res => res));
  }

  addDirectors(id, info)
  {
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/addDirectors/' + id, info, {headers})
    return this.http.post(this.baseUrl+'/addDirectors/' + id, info)
     .pipe(retry(0),map(res => res));
  }

  addImprovements(id, info)
  {
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/addImprovements/' + id, info, {headers})
    return this.http.post(this.baseUrl+'/addImprovements/' + id, info)
     .pipe(retry(0),map(res => res));
  }

  getBusinesses()
  { var data="none"
     return this.http.post(this.baseUrl+'/getBusinesses', data)
   .pipe(retry(4),map(res => res));
  }

  getBusinessbyuserid(u){
    return this.http.post(this.baseUrl+'/getBusinessbyuserid', {"id":u})
    .pipe(retry(4));
  }

  getBusinessbyid(i){
    return this.http.post(this.baseUrl+'/getBusinessbyid', {"id":i})
    .pipe(retry(4));
  }

  getBusinessbyaccountid(i){
    return this.http.post(this.baseUrl+'/getBusinessbyaccountid', {"accountid":i})
    .pipe(retry(4));
  }

  getImage(f){
    return this.http.post(this.baseUrl+'/getImage', {"filename":f})
    .pipe(retry(4));
  }

  getBusinessbyservice(s){
    return this.http.post(this.baseUrl+'/getBusinessbyservice', {"service":s})
    .pipe(retry(4));
  }

  getBusinessbylocation(l){
    return this.http.post(this.baseUrl+'/getBusinessbylocation', {"location":l})
    .pipe(retry(4));
  }

  getBusinessbyls(l,s){
    return this.http.post(this.baseUrl+'/getBusinessbyls', {"location":l,"service":s})
    .pipe(retry(4));
  }

  singUp(data){
    console.log('SIGNUP')
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/signup', data, {headers});
    return this.http.post(this.baseUrl+'/signup', data)
  }

  verifyEmail(t){
    return this.http.post(this.baseUrl+'/verifyuser', {"token":t})
    .pipe(retry(0));
  }

  verifyEmailbycode(c,e,p){
    console.log('CEP='+c+' ' +e+' '+p)
    return this.http.post(this.baseUrl+'/verifyuserbycode', {"vericode":c, "email":e, "password":p})
    .pipe(retry(4));
  }

  verifyResetbycode(c,e,){
    return this.http.post(this.baseUrl+'/verifyResetbycode', {"vericode":c, "email":e})
    .pipe(retry(4));
  }

  initReset(e){
    return this.http.post(this.baseUrl+'/initReset', {"email":e})
    .pipe(retry(4));
  }

  checkEmail(e){
    return this.http.post(this.baseUrl+'/checkEmail', {"email":e})
    .pipe(retry(4));
  }

  getVerification(e){
    return this.http.post(this.baseUrl+'/getVerificatation', {"email":e})
    .pipe(retry(4));
  }

  resendVerification(e){
    console.log('E='+e)
    return this.http.post(this.baseUrl+'/resendVerification', {"email":e})
    .pipe(retry(4));
  }

  getUsers()
  {
     return this.http.get(this.baseUrl+'/getUsers')
   .pipe(retry(10),map(res => res));
  }

  getUserbyid(id){
    return this.http.post(this.baseUrl+'/getUserbyid', {"id":id})
      .pipe(retry(4));
    }

  updateUserstatus(info)
  {
   return this.http.post(this.baseUrl+'/updateuserstatus', info)
     .pipe(retry(4),map(res => res));
  }

  updateUserprofile(info)
  {
   return this.http.post(this.baseUrl+'/updateUserprofile', info)
     .pipe(retry(4),map(res => res));
  }

  updateBusiness(info)
  {
   return this.http.post(this.baseUrl+'/updateBusiness', info)
     .pipe(retry(2),map(res => res));
  }

  getPartnerbyid(id){
    return this.http.post(this.baseUrl+'/getpartnerbyid', {"id":id})
    .pipe(retry(4));
   }

  logIn(data){
    return this.http.post(this.baseUrl+'/login', data)
    .pipe(retry(2),map(res => res));
  }

  getGooglelogin(value: boolean){
    this.googleUser.next(value);
    console.log('GoogleUser'+JSON.stringify(value));
  }

  adminResetrequest(info){
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post(this.baseUrl+'/adminReset', info, {headers})
    .pipe(retry(4));
  }

  changePassword(data){
    //console.log('R='+(data))
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post(this.baseUrl+'/resetPassword', data, {headers})
    .pipe(retry(4));
  }

  changePasswordbycode(data){
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post(this.baseUrl+'/resetPasswordbycode', data, {headers})
    .pipe(retry(4));
  }

  sendVerifyemail(id){
    return this.http.post(this.baseUrl+'/retrievetoken', {"id":id})
    .pipe(retry(4));
  }

  getUserrole(id)
  {
    return this.http.post(this.baseUrl+'/getuserrole', {"id":id})
     .pipe(retry(4),map(res => res));
  }

  getAvatarinitials(value : string){
    this.avatarInitials.next(value);
   }

  adminUser() {
    this._isAdmin.next(true); 
  }

  capturerUser() {
    this._isCapturer.next(true); 
  }

  ownerUser() {
    this._isOwner.next(true); 
  }

  superUser() {
    this._isSuper.next(true); 
  }

  partnerUser() {
    this._isPartner.next(true); 
  }

  refresh(){
    return this.http.get(this.baseUrl+'/refresh')
   .pipe(retry(4),map(res => res));
  }
  
  resetRoles() {
    this._isAdmin.next(false);
    this._isCapturer.next(false);
    this._isOwner.next(false);
    this._isSuper.next(false);
    this._isPartner.next(false);
  }

  resetAvatar() {
   this.avatarName.next(null);
   this.avatarInitials.next(null);
   this.hasAvatar.next(false);
  }

}
