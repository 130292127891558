import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import {MatTableDataSource} from '@angular/material/table';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatStepper } from '@angular/material/stepper';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.css']
})
export class CaptureComponent implements OnInit {
  @ViewChild('viewbusinessModal') viewbusinessModal;
  @ViewChild('stepper') private myStepper: MatStepper;
  @ViewChild('submitting') submitting;
  
  faSpinner = faSpinner;
  basicFormGroup: FormGroup;
  adminFormGroup: FormGroup;
  businessFormGroup: FormGroup;
  contactFormGroup: FormGroup;
  experienceFormGroup: FormGroup;
  directorFormGroup: FormGroup;
  breakdownFormGroup: FormGroup;
  statutoryFormGroup: FormGroup;
  expertiseFormGroup: FormGroup;
  isEditable = true;
  blackTotal=10;
  whiteTotal=5;
  grandTotal=15;
  sharebtot=0;
  sharewtot=0;
  sharetot=0;
  seniorbtot=0;
  seniorwtot=0;
  seniortot=0;
  staffbtot=0;
  staffwtot=0;
  stafftot=0;
  tempbtot=0;
  tempwtot=0;
  temptot=0;
  bmtot=0;
  bftot=0;
  wmtot=0;
  wftot=0;
  wtot=0;
  btot=0;
  grandtot=0;
  _business:any;
  _works:any;
  _directors:any;
  _improvements:any;
  businessid:any;
  error:any;
  obs: Observable<any>;
  _subindustry:any;
  userid:any;
  loggedIn:boolean;
  _submitting:string;
  part:any;
  partnerid:any;

  business:any;
  directors:any[];
  works:any[];
  improvements:any[];

  faCoffee = faCoffee;
  renderChecks:boolean;
  
  displayedColumns: string[] = ['name', 'surname', 'nationality', 'race','gender', 'youth', 'shareholding', 'action'];
  dirdataSource: MatTableDataSource<d>;
  _dirdataSource: MatTableDataSource<d>;

  displayedColumns_works: string[] = ['client', 'description', 'value', 'action'];
  workdataSource: MatTableDataSource<w>;
  _workdataSource: MatTableDataSource<w>;
  
  displayedColumns_bd: string[] = ['t','sharebm', 'sharebf', 'sharebtot'];
  //workdataSource: MatTableDataSource<w>;

  bbbee: Location[] = [
    {value: '0', viewValue: 'Non-compliant'},
    {value: '1', viewValue: 'Level 8'},
    {value: '2', viewValue: 'Level 7'},
    {value: '3', viewValue: 'Level 6'},
    {value: '4', viewValue: 'Level 5'},
    {value: '5', viewValue: 'Level 4'},
    {value: '6', viewValue: 'Level 3'},
    {value: '7', viewValue: 'Level 2'},
    {value: '8', viewValue: 'Level 1'},
  ];

  tourism: Location[] = [
    {value: '0', viewValue: '1 Star'},
    {value: '1', viewValue: '2 Stars'},
    {value: '2', viewValue: '3 Stars'},
    {value: '3', viewValue: '4 Stars'},
    {value: '4', viewValue: '5 Stars'},
   
  ];

  locations: Location[] = [
    {value: '0', viewValue: 'Urban'},
    {value: '1', viewValue: 'Rural'},
    {value: '2', viewValue: 'Periurban'}
  ];

  compliant: Location[] = [
    {value: '0', viewValue: 'Non-Compliant'},
    {value: '1', viewValue: 'Compliant'},
  ];

  stages: Location[] = [
    {value: '0', viewValue: 'Starter'},
    {value: '1', viewValue: 'Mid business'},
    {value: '2', viewValue: 'Other'}
  ];

  industry: Location[] = [
    {value: '0', viewValue: 'Agriculture'},
    {value: '1', viewValue: 'Forestry'},
    {value: '2', viewValue: 'Tourism'},
    {value: '3', viewValue: 'Retail'},
    {value: '4', viewValue: 'Fuel & Petroleum'},
    {value: '5', viewValue: 'Mining'},
    {value: '6', viewValue: 'Manufacturing'},
    {value: '7', viewValue: 'Logistics & Transport'},
    {value: '8', viewValue: 'Informal Trading'},
    {value: '9', viewValue: 'Spaza Shops'},
    {value: '10', viewValue: 'Other'}
  ];

  subindustry: I[] = [
    {value: '0', viewValue: 'Animal Production', industry: 'Agriculture'},
    {value: '1', viewValue: 'Plant Production', industry: 'Agriculture'},
    {value: '2', viewValue: 'AgroProcessing', industry: 'Agriculture'},
    {value: '3', viewValue: 'Abattoir', industry: 'Agriculture'},
    {value: '4', viewValue: 'Food', industry: 'Retail'},
    {value: '5', viewValue: 'Clothing', industry: 'Retail'},
    {value: '6', viewValue: 'Hardware', industry: 'Retail'},
    {value: '7', viewValue: 'Furniture', industry: 'Retail'},
    {value: '8', viewValue: 'Haulage', industry: 'Logistics & Transport'},
    {value: '9', viewValue: 'Taxi Industry', industry: 'Logistics & Transport'},
    {value: '10', viewValue: 'Courier', industry: 'Logistics & Transport'},
    {value: '11', viewValue: 'Buses', industry: 'Logistics & Transport'},
    {value: '11', viewValue: 'Shuttle', industry: 'Logistics & Transport'},
    {value: '12', viewValue: 'Fruits & Vegetables', industry: 'Informal Trading'},
    {value: '13', viewValue: 'Traditional Medicines', industry: 'Informal Trading'},
  ];

  turnover: Location[] = [
    {value: '1', viewValue: '< 1M'},
    {value: '2', viewValue: '1M - 5M'},
    {value: '3', viewValue: '5M>'}
  ];

  clientelle: Location[] = [
    {value: '1', viewValue: 'Private Sector'},
    {value: '2', viewValue: 'Government'},
    {value: '3', viewValue: 'Other'}
  ];

  education: Location[] = [
    {value: '1', viewValue: 'Matric'},
    {value: '2', viewValue: 'Degree'},
    {value: '3', viewValue: 'Other'}
  ];

  gender: Location[] = [
    {value: '1', viewValue: 'Male'},
    {value: '2', viewValue: 'Female'},
    {value: '3', viewValue: 'Other'}
  ];

  nationality: Location[] = [
    {value: '1', viewValue: 'South African'},
    {value: '2', viewValue: 'Other'}
  ];


  yn: Location[] = [
    {value: '1', viewValue: 'Yes'},
    {value: '2', viewValue: 'No'},
  ];

  race: Location[] = [
    {value: '1', viewValue: 'Black'},
    {value: '2', viewValue: 'Coloured'},
    {value: '3', viewValue: 'White'},
    {value: '4', viewValue: 'Indian'},
    {value: '5', viewValue: 'Other'}
  ];

  rating: Location[] = [
    {value: '1', viewValue: 'Poor'},
    {value: '2', viewValue: 'Average'},
    {value: '3', viewValue: 'Satisfactory'},
    {value: '4', viewValue: 'Good'},
    {value: '5', viewValue: 'Excellent'}
  ];

  fin:E[]=[
    {id:1,description:"Accounting, Payments Systems",expertise:"Finance & Legal"},
    {id:2,description:"Cash Flow Projections, Financial Planning & Budgets, Insurance",expertise:"Finance & Legal"},
    {id:3,description:"Business Plan Development (including application for funding)",expertise:"Finance & Legal"},
    {id:4,description:"Costing/Controls – Inventory, Taxation",expertise:"Finance & Legal"},
    {id:5,description:"Debt Collection and Counselling",expertise:"Finance & Legal"},
    {id:6,description:"Purchasing & Procurement",expertise:"Finance & Legal"},
    {id:7,description:"Patents & Trade Marks, Merges & Acquisitions",expertise:"Finance & Legal"},
    {id:8,description:"Legal (incl. Company Law), Company Formations",expertise:"Finance & Legal"},
    {id:9,description:"Due Diligence",expertise:"Finance & Legal"},
  ]

  hr:E[]=[
    {id:10,description:"Training (Financial Management, Marketing, Technical, Quality, Export Management)",expertise:"Human Resources Development"},
    {id:11,description:"Mentorship",expertise:"Human Resources Development"},
    {id:12,description:"Employment, Recruitment, Payrolls",expertise:"Human Resources Development"},
    {id:13,description:"Labour Law and Industrial Relations",expertise:"Human Resources Development"},
    {id:14,description:"Black Economic Empowerment (BEE)",expertise:"Human Resources Development"},
    {id:15,description:"Skills Development Planning (Competency Assessments, Training Plans, Skills Gaps)",expertise:"Human Resources Development"},
    {id:16,description:"HR Policies & Procedures (Performance Management, Disciplinary Procedures, Remuneration Policies)",expertise:"Human Resources Development"},
    
  ]

  productivity:E[]=[
    {id:17,description:"Product Development",expertise:"Productivity/Technology"},
    {id:18,description:"Manufacturing Processes, Production & Operational Management",expertise:"Productivity/Technology"},
    {id:19,description:"Process Development (Factory Layout Work Study, Productivity Improvement, Problem Solving Techniques)",expertise:"Productivity/Technology"},
    {id:20,description:"Information & Communications Technology (ICT)",expertise:"Productivity/Technology"},
  ]

  marketing:E[]=[
    {id:21,description:"Marketing Plans/Strategy/Research",expertise:"Marketing"},
    {id:22,description:"Signage, Branding, Sales & Merchandising",expertise:"Marketing"},
    {id:23,description:"Promotional Material (Brochures/Flyers)",expertise:"Marketing"},
    {id:24,description:"E-Marketing (Website Development, E-Commerce)",expertise:"Marketing"},
    {id:25,description:"Trade Exhibitions, Advertising, Public Relations",expertise:"Marketing"},
    {id:26,description:"Export & Import Procedures",expertise:"Marketing"},
    {id:27,description:"Market Facilitation (Tender Advice, Business Linkages)",expertise:"Marketing"},
  ]

  quality:E[]=[
    {id:28,description:"QMS Implementation",expertise:"Quality"},
    {id:29,description:"Project Management",expertise:"Quality"},
    {id:30,description:"QMS Certification",expertise:"Quality"},
    {id:31,description:"Product Certification/Testing",expertise:"Quality"},
  ]

  crm:E[]=[//Iused name cause I need to filter this array by province and my filter function expects name as filter field
    
    {id:32,description:"Customer Care Policy",expertise:"Customer Relations Management"},
    {id:33,description:"Customer Care Strategy",expertise:"Customer Relations Management"},
    {id:34,description:"Customer Service Charter (Service Standards)",expertise:"Customer Relations Management"},
    {id:35,description:"Customer Satisfaction Survey",expertise:"Customer Relations Management"},



  ];

  constructor(
    private _formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private dataService:DataService,
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this.authService.authStatus.subscribe(value => this.loggedIn = value);
   
    //Perform the below to retrieve user avatar / initials when the user is logged on and the navbar is loading
    //The observable values, would have not been set from anywhere else
    if (this.loggedIn){
      this.userid=localStorage.getItem('id');
      console.log('USERID'+this.userid);
      }
    this.basicFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      website: [],
      email: [],
      tel: [],
      fax: [],
      rstreet: [],
      rsuburb: [],
      rtown: [],
      rcode: [],
      pstreet: [],
      psuburb: [],
      ptown: [],
      pcode: [],
      fbpage: [],
      description: [],
    });
    this.adminFormGroup = this._formBuilder.group({
      location: [],
      stage: [],
      industry: [],
      subindustry: [],
      tradinglocation: [],
      turnover: [],
      service: [],
      vision: [],
      fiveyears: [],
      clientelle: [],
    });
    this.businessFormGroup = this._formBuilder.group({
      vision: [],
      fiveyears: [],
      clientelle: [],
    });

    this.contactFormGroup = this._formBuilder.group({
      name: [],
      surname: [],
      idno: [],
      contactnumber: [],
      email: [],
      race: [],
      education: []
    });

    this.experienceFormGroup = this._formBuilder.group({
      client: [],
      description: [],
      value: [],
      
    });

    this.directorFormGroup = this._formBuilder.group({
      name: [],
      surname: [],
      nationality: [],
      race: [],
      gender: [],
      youth: [],
      shareholding: [],
    });

    this.breakdownFormGroup = this._formBuilder.group({
      sharebm: [],
      sharebf: [],
      sharewm: [],
      sharewf: [],
      seniorbm: [],
      seniorbf: [],
      seniorwm: [],
      seniorwf: [],
      staffbm: [],
      staffbf: [],
      staffwm: [],
      staffwf: [],
      tempbm: [],
      tempbf: [],
      tempwm: [],
      tempwf: [],
      
    });

    this.statutoryFormGroup = this._formBuilder.group({
      regno: [],
      taxno: [],
      sarspin: [],
      vat: [],
      bbbee: [],
      tourism: [],
      cidb: [],
      industry: [],
      coida: [],
      sabs: [],
      iso: [],
      health: [],
      env: [],
      planning: [],
      tourismaffiliation: [],
      businesspermit: [],
     
    });

    this.expertiseFormGroup = this._formBuilder.group({
      fin: [],
      sales: [],
      business: [],
      hr: [],
      strat: [],
      tax: [],
      marketing: [],
      presenting: [],
      tendering: [],
      
     
    });

    this.directors = [];
    this.works = [];
    this.improvements = [];
    this.renderChecks=true;
    let _id=1;
    this.getPartnerbyid(_id);
  }

  getPartnerbyid(i){
    //this.isLoading=true;
    this.dataService.getPartnerbyid(i)
          .subscribe((part) => {
          this.part = part[0][0];
          console.log('part='+JSON.stringify(this.part));
          this.partnerid=this.part.id;
          console.log('partnerID='+JSON.stringify(this.partnerid));
            //this.setP();
          //this.isLoading=false;
        },
        errors => {
         // this.errors = errors;
          //this.haserror=true;
          //this.isLoading=false;
          //this.errormessage = "Error loading policy information, please try again"
        }
        );
  }

  next(){
    let name = this.basicFormGroup.controls.name.value;
    let website = this.basicFormGroup.controls.website.value;
    let email = this.basicFormGroup.controls.email.value;
    let tel = this.basicFormGroup.controls.tel.value;
    let fax = this.basicFormGroup.controls.fax.value;
    let rstreet = this.basicFormGroup.controls.rstreet.value;
    let rsuburb = this.basicFormGroup.controls.rsuburb.value;
    let rtown = this.basicFormGroup.controls.rtown.value;
    let rcode = this.basicFormGroup.controls.rcode.value;
    let pstreet = this.basicFormGroup.controls.pstreet.value;
    let psuburb = this.basicFormGroup.controls.psuburb.value;
    let ptown = this.basicFormGroup.controls.ptown.value;
    let pcode = this.basicFormGroup.controls.pcode.value;

    let location = this.adminFormGroup.controls.location.value;
    let stage = this.adminFormGroup.controls.stage.value;
    let industry = this.adminFormGroup.controls.industry.value;
    let subindustry = this.adminFormGroup.controls.subindustry.value;
    let tradinglocation = this.adminFormGroup.controls.tradinglocation.value;
    let turnover = this.adminFormGroup.controls.turnover.value;
    let service = this.adminFormGroup.controls.service.value;
    let vision = this.adminFormGroup.controls.vision.value;
    let fiveyears = this.adminFormGroup.controls.fiveyears.value;
    let clientelle = this.adminFormGroup.controls.clientelle.value;


    let regno = this.statutoryFormGroup.controls.regno.value;
    let taxno = this.statutoryFormGroup.controls.taxno.value;
    let sarspin = this.statutoryFormGroup.controls.sarspin.value;
    let vat = this.statutoryFormGroup.controls.vat.value;
    let bbbee = this.statutoryFormGroup.controls.bbbee.value;
    let tourism = this.statutoryFormGroup.controls.tourism.value;
    let cidb = this.statutoryFormGroup.controls.cidb.value;
    let statindustry = this.statutoryFormGroup.controls.industry.value;
    let coida = this.statutoryFormGroup.controls.coida.value;
    let sabs = this.statutoryFormGroup.controls.sabs.value;
    let iso = this.statutoryFormGroup.controls.iso.value;
    let health = this.statutoryFormGroup.controls.health.value;
    let env = this.statutoryFormGroup.controls.env.value;
    let planning = this.statutoryFormGroup.controls.planning.value;
    let tourismaffiliation = this.statutoryFormGroup.controls.tourismaffiliation.value;
    let businesspermit = this.statutoryFormGroup.controls.businesspermit.value;

    let contactname = this.contactFormGroup.controls.name.value;
    let surname = this.contactFormGroup.controls.surname.value;
    let idno = this.contactFormGroup.controls.idno.value;
    let contactnumber = this.contactFormGroup.controls.contactnumber.value;
    let contactemail = this.contactFormGroup.controls.email.value;
    let race = this.contactFormGroup.controls.race.value;
    let education = this.contactFormGroup.controls.education.value;

    let sharebm = this.breakdownFormGroup.controls.sharebm.value;
    let sharebf = this.breakdownFormGroup.controls.sharebf.value;
    let sharewm = this.breakdownFormGroup.controls.sharewm.value;
    let sharewf = this.breakdownFormGroup.controls.sharewf.value;
    let seniorbm = this.breakdownFormGroup.controls.seniorbm.value;
    let seniorbf = this.breakdownFormGroup.controls.seniorbf.value;
    let seniorwm = this.breakdownFormGroup.controls.seniorwm.value;
    let seniorwf = this.breakdownFormGroup.controls.seniorwf.value;
    let staffbm = this.breakdownFormGroup.controls.staffbm.value;
    let staffbf = this.breakdownFormGroup.controls.staffbf.value;
    let staffwm = this.breakdownFormGroup.controls.staffwm.value;
    let staffwf = this.breakdownFormGroup.controls.staffwf.value;
    let tempbm = this.breakdownFormGroup.controls.tempbm.value;
    let tempbf = this.breakdownFormGroup.controls.tempbf.value;
    let tempwm = this.breakdownFormGroup.controls.tempwm.value;
    let tempwf = this.breakdownFormGroup.controls.tempwf.value;

    let fin = this.expertiseFormGroup.controls.fin.value;
    let sales = this.expertiseFormGroup.controls.sales.value;
    let business = this.expertiseFormGroup.controls.business.value;
    let hr = this.expertiseFormGroup.controls.hr.value;
    let strat = this.expertiseFormGroup.controls.strat.value;
    let tax = this.expertiseFormGroup.controls.tax.value;
    let marketing = this.expertiseFormGroup.controls.marketing.value;
    let presenting = this.expertiseFormGroup.controls.presenting.value;
    let tendering = this.expertiseFormGroup.controls.tendering.value;
    
    

    this.business = {name:name, website:website, email:email, tel:tel, fax:fax, rstreet:rstreet, rsuburb:rsuburb, rtown:rtown, rcode:rcode, 
      pstreet:pstreet, psuburb:psuburb, ptown:ptown,pcode:pcode,fbpage:'',description:'',

      location:location, stage:stage, industry:industry, subindustry:subindustry, tradinglocation:tradinglocation, turnover:turnover, service:service, vision:vision, 
      fiveyears:fiveyears, clientelle:clientelle,

    contactname:contactname, surname:surname, idno:idno, contactnumber:contactnumber, contactemail:contactemail, race:race, education:education,
    
    regno:regno, taxno:taxno, sarspin:sarspin, vat:vat, bbbee:bbbee, tourism:tourism, cidb:cidb, statindustry:statindustry, coida:coida, sabs:sabs,
    iso:iso, health:health, env:env, planning:planning, tourismaffiliation:tourismaffiliation, businesspermit:businesspermit,


    sharebm:sharebm, sharebf:sharebf, sharewm:sharewm, sharewf:sharewf, seniorbm:seniorbm, seniorbf:seniorbf, seniorwm:seniorwm,
    seniorwf:seniorwf, staffbm:staffbm, staffbf:staffbf, staffwm:staffwm, staffwf:staffwf, tempbm:tempbm, tempbf:tempbf, tempwm:tempwm, tempwf:tempwf,

    fin:fin, sales:sales, business:business, hr:hr, strat:strat, tax:tax, marketing:marketing,
    presenting:presenting, tendering:tendering,


  }
}

addDirector(){

  let dirname = this.directorFormGroup.controls.name.value;
  let dirsurname = this.directorFormGroup.controls.surname.value;
  let nationality = this.directorFormGroup.controls.nationality.value;
  let shareholding = this.directorFormGroup.controls.shareholding.value;
  let gender = this.directorFormGroup.controls.gender.value;
  let dirrace = this.directorFormGroup.controls.race.value;
  let youth = this.directorFormGroup.controls.youth.value;
  
  this.directors.push({id: this.directors.length+1, dirname:dirname, dirsurname:dirsurname, nationality:nationality, shareholding:shareholding, gender:gender, dirrace:dirrace, youth:youth})

  this.dirdataSource = new MatTableDataSource(this.directors);

  this.changeDetectorRef.detectChanges();
  this.obs = this.dirdataSource.connect();
  this.resetDirector();
}

removeDirector(id){
  console.log("Directors="+JSON.stringify(this.directors))
  console.log("ID="+id)
  this.directors = this.directors.filter(directors => directors.id != id);
  this.dirdataSource = new MatTableDataSource(this.directors);
  this.changeDetectorRef.detectChanges();
  this.obs = this.dirdataSource.connect();
}

addWork(){
  console.log('AddWork');

  let client = this.experienceFormGroup.controls.client.value;
  let description = this.experienceFormGroup.controls.description.value;
  let value = this.experienceFormGroup.controls.value.value;
  
  this.works.push({id: this.works.length+1, client:client, description:description, value:value})

  this.workdataSource = new MatTableDataSource(this.works);

  this.changeDetectorRef.detectChanges();
  this.obs = this.workdataSource.connect();
  this.resetWorks();
}

removeWork(id){
  console.log("Works="+JSON.stringify(this.works))
  console.log("ID="+id)
  this.works = this.works.filter(works => works.id != id);
  this.workdataSource = new MatTableDataSource(this.works);
  this.changeDetectorRef.detectChanges();
  this.obs = this.workdataSource.connect();
}


directorsNext(){
  if(this.directorFormGroup.controls.name.value!=null || this.directorFormGroup.controls.surname.value!=null || this.directorFormGroup.controls.nationality.value  || this.directorFormGroup.controls.shareholding.value!=null || this.directorFormGroup.controls.gender.value!=null || this.directorFormGroup.controls.race.value || this.directorFormGroup.controls.youth.value){
    console.log("Saving unsaved director work");
    this.addDirector();
  }

}

workNext(){
  if((this.experienceFormGroup.controls.client.value!='' && this.experienceFormGroup.controls.client.value!=null) || this.experienceFormGroup.controls.description.value!=null || this.experienceFormGroup.controls.value.value!=null){
  //if(!this.experienceFormGroup.invalid){
  console.log("Saving unsaved work"+this.experienceFormGroup.controls.client.value);
    this.addWork();
  }
}


setImprov(completed: boolean, descr, exp, i) {
  console.log("Improv="+completed)
  console.log("Descr="+descr)
  console.log("Exp="+exp)
  if(completed){
    this.improvements.push({expertise:exp, description:descr, imprid:i})
  }else{
    this.improvements = this.improvements.filter(improvements => improvements.imprid != i);
  }

}

calcBreakdown(){
  this.sharebtot = Number(this.breakdownFormGroup.controls.sharebm.value) + Number(this.breakdownFormGroup.controls.sharebf.value);
  this.sharewtot = Number(this.breakdownFormGroup.controls.sharewm.value) + Number(this.breakdownFormGroup.controls.sharewf.value);
  this.sharetot = this.sharebtot+this.sharewtot;

  this.seniorbtot = Number(this.breakdownFormGroup.controls.seniorbm.value) + Number(this.breakdownFormGroup.controls.seniorbf.value);
  this.seniorwtot = Number(this.breakdownFormGroup.controls.seniorwm.value) + Number(this.breakdownFormGroup.controls.seniorwf.value);
  this.seniortot = this.seniorbtot+this.seniorwtot;

  this.staffbtot = Number(this.breakdownFormGroup.controls.staffbm.value) + Number(this.breakdownFormGroup.controls.staffbf.value);
  this.staffwtot = Number(this.breakdownFormGroup.controls.staffwm.value) + Number(this.breakdownFormGroup.controls.staffwf.value);
  this.stafftot = this.staffbtot+this.staffwtot;

  this.tempbtot = Number(this.breakdownFormGroup.controls.tempbm.value) + Number(this.breakdownFormGroup.controls.tempbf.value);
  this.tempwtot = Number(this.breakdownFormGroup.controls.tempwm.value) + Number(this.breakdownFormGroup.controls.tempwf.value);
  this.temptot = this.tempbtot+this.tempwtot;

  this.bmtot = Number(this.breakdownFormGroup.controls.sharebm.value)+Number(this.breakdownFormGroup.controls.seniorbm.value)+Number(this.breakdownFormGroup.controls.staffbm.value)+Number(this.breakdownFormGroup.controls.tempbm.value);
  this.bftot = Number(this.breakdownFormGroup.controls.sharebf.value)+Number(this.breakdownFormGroup.controls.seniorbf.value)+Number(this.breakdownFormGroup.controls.staffbf.value)+Number(this.breakdownFormGroup.controls.tempbf.value);

  this.wmtot = Number(this.breakdownFormGroup.controls.sharewm.value)+Number(this.breakdownFormGroup.controls.seniorwm.value)+Number(this.breakdownFormGroup.controls.staffwm.value)+Number(this.breakdownFormGroup.controls.tempwm.value);
  this.wftot = Number(this.breakdownFormGroup.controls.sharewf.value)+Number(this.breakdownFormGroup.controls.seniorwf.value)+Number(this.breakdownFormGroup.controls.staffwf.value)+Number(this.breakdownFormGroup.controls.tempwf.value);

  this.btot=this.bmtot+this.bftot;
  this.wtot=this.wmtot+this.wftot;
  this.grandtot=this.btot+this.wtot;

}

submit(){
  console.log('Submitting');
  this._submitting="SUBMITTING BASIC BUSINESS INFO"
  this.openModal(this.submitting);
  var b = {"name":this.business.name, "website":this.business.website, "email":this.business.email, "tel":this.business.tel, "fax":this.business.fax, "rstreet":this.business.rstreet, "rsuburb":this.business.rsuburb, "rtown":this.business.rtown, "rcode":this.business.rcode, 
    "pstreet":this.business.pstreet, "psuburb":this.business.psuburb, "ptown":this.business.ptown, "pcode":this.business.pcode,
    "fbpage":'',"description":'',

    "location":this.business.location, "stage":this.business.stage, "industry":this.business.industry, "subindustry":this.business.subindustry, "tradinglocation":this.business.tradinglocation, "turnover":this.business.turnover, "service":this.business.service, "vision":this.business.vision, 
    "fiveyears":this.business.fiveyears, "clientelle":this.business.clientelle,

  "contactname":this.business.contactname, "surname":this.business.surname, "idno":this.business.idno, "contactnumber":this.business.contactnumber, "contactemail":this.business.contactemail, "race":this.business.race, "education":this.business.education,
  
  "regno":this.business.regno, "taxno":this.business.taxno, "sarspin":this.business.sarspin, "vat":this.business.vat, "bbbee":this.business.bbbee, "tourism":this.business.tourism, "cidb":this.business.cidb, "statindustry":this.business.statindustry, "coida":this.business.coida, "sabs":this.business.sabs,
  "iso":this.business.iso, "health":this.business.health, "env":this.business.env, "planning":this.business.planning, "tourismaffiliation":this.business.tourismaffiliation, "businesspermit":this.business.businesspermit,


  "sharebm":this.business.sharebm, "sharebf":this.business.sharebf, "sharewm":this.business.sharewm, "sharewf":this.business.sharewf, seniorbm:this.business.seniorbm, seniorbf:this.business.seniorbf, seniorwm:this.business.seniorwm,
  "seniorwf":this.business.seniorwf, "staffbm":this.business.staffbm, "staffbf":this.business.staffbf, "staffwm":this.business.staffwm, staffwf:this.business.staffwf, tempbm:this.business.tempbm, tempbf:this.business.tempbf, tempwm:this.business.tempwm, tempwf:this.business.tempwf,

  "fin":this.business.fin, "sales":this.business.sales, "business":this.business.business, "hr":this.business.hr, "strat":this.business.strat, "tax":this.business.tax, "marketing":this.business.marketing,
  "presenting":this.business.presenting, "tendering":this.business.tendering, "userid":this.userid, "institutionid":this.partnerid

  }

  this.dataService.addBusiness(JSON.stringify(b))
    .subscribe((res)=>{
      this._business=res;
      console.log(JSON.stringify(res));
      this.businessid=this._business.id;
      console.log("businessid="+JSON.stringify(this.businessid));
     
      //this.saveQualification();
      //if(this.works.length>0){
        this.submitWorks();
      //}
  },
  errors =>  
  {
    this.valaModal();
    this.error = errors.error.errors;
    //window.scrollTo(0,0);
  }
);
}

submitWorks(){
  if(this.works.length>0){
  this._submitting="SUBMITTING WORK DONE INFO"
  console.log('Submitting Works');
  this.dataService.addWorks(this.businessid, JSON.stringify(this.works))
    .subscribe((res)=>{
      this._works=res;
      console.log(JSON.stringify(res));
      this._workdataSource = new MatTableDataSource(this._works);
      this.changeDetectorRef.detectChanges();
      this.obs = this._workdataSource.connect();
      //if(this.directors.length>0){
        this.submitDirectors();
      //}
      
  },
  errors =>  
  {
    this.valaModal();
    this.error = errors.error.errors;
    //window.scrollTo(0,0);
  }
);
  }else{
    this.submitDirectors();
  }
}

submitDirectors(){
  if(this.directors.length>0){
    this._submitting="SUBMITTING DIRECTOR(S) INFO"
  console.log('Submitting Directors');
  this.dataService.addDirectors(this.businessid, JSON.stringify(this.directors))
    .subscribe((res)=>{
      this._directors=res;
      console.log(JSON.stringify(res));
      this._dirdataSource = new MatTableDataSource(this._directors);
      this.changeDetectorRef.detectChanges();
      this.obs = this._dirdataSource.connect();
     // if(this.improvements.length>0){
        this.submitImprovements();
     // }
      
  },
  errors =>  
  {
    this.valaModal();
    this.error = errors.error.errors;
    //window.scrollTo(0,0);
  }
);
  }else{
    this.submitImprovements();
  }
}

submitImprovements(){
  if(this.improvements.length>0){
    this._submitting="SUBMITTING SERVICE IMPROVEMENT INFO"
  console.log('Submitting Improvements');
  this.dataService.addImprovements(this.businessid, JSON.stringify(this.improvements))
    .subscribe((res)=>{
      this._improvements=res;
      console.log(JSON.stringify(res));
      this.valaModal();
      this.openModal(this.viewbusinessModal);
  },
  errors =>  
  {
    this.valaModal();
    this.openModal(this.viewbusinessModal);
    this.error = errors.error.errors;
    //window.scrollTo(0,0);
  }
);
  }else{
    this.valaModal();
    this.openModal(this.viewbusinessModal);
  }
}

headerNext(){
  this.next();
  this.workNext();
  this.directorsNext();
}

resetWorks(){
  this.experienceFormGroup.controls.client.reset();
  this.experienceFormGroup.controls.description.reset();
  this.experienceFormGroup.controls.value.reset();
}

resetDirector(){
  this.directorFormGroup.controls.name.reset();
  this.directorFormGroup.controls.surname.reset();
  this.directorFormGroup.controls.nationality.reset();
  this.directorFormGroup.controls.shareholding.reset();
  this.directorFormGroup.controls.gender.reset();
  this.directorFormGroup.controls.race.reset();
  this.directorFormGroup.controls.youth.reset();
}

loadSub(){
  
  this._subindustry=this.filterArray(this.adminFormGroup.controls.industry.value, this.subindustry);
  
}

filterArray(i, arrayData){
  console.log('arrayData='+ (JSON.stringify(arrayData)));
  console.log('i='+ (i));
  var filtered = arrayData.filter(
    arrayData => (arrayData.industry.toString().toLowerCase()) === i.toString().toLowerCase());
    //arrayData => (arrayData.industry) === i);
    console.log('filtered='+ JSON.stringify(filtered));
    return filtered;
}

captureAnother(){
  this.renderChecks=false; //reset the form with checkboxes, wasn't removing previous values, in case of capture again
  this.myStepper.reset();
  this.valaModal();
  this.basicFormGroup.reset();
  this.adminFormGroup.reset();
  this.statutoryFormGroup.reset();
  this.contactFormGroup.reset();
  this.breakdownFormGroup.reset();
  this.expertiseFormGroup.reset();
  this.directorFormGroup.reset();
  this.business=[];
  this.directors = [];
  this.works = [];
  this.improvements = [];
  this.dirdataSource = new MatTableDataSource(this.works);
  this.changeDetectorRef.detectChanges();
  this.obs = this.dirdataSource.connect();
  this.workdataSource = new MatTableDataSource(this.works);
  this.changeDetectorRef.detectChanges();
  this.obs = this.workdataSource.connect();
  this.renderChecks=true;
}

openModal(f) {
  this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
  }).result.then((result) => {
  }, (reason) => {
  });
}

valaModal(){
  this.modalService.dismissAll();
}

goHome(){
  this.valaModal();
  this.router.navigateByUrl('/home');
}

}

interface Location {
  value: string;
  viewValue: string;
}

interface I {
  value: string;
  viewValue: string;
  industry: string;
}

interface E{
  id:number,
  expertise:string,
  description: string;
}



interface d{
  name: string,
  surname: string,
  nationality: string,
  race: string,
  gender: string,
  youth: string,
  shareholding: string,
}

interface w{
  client: string,
  description: string,
  value: string,
  t: string,
}


