
    <div class="container height-100 d-flex justify-content-center align-items-center"> 
        <div class="position-relative"> 
          <div class="card p-2 text-center"> 
            <h5>Forgot Password</h5>
            <div *ngIf="canReset">
              <div> 
                  <span>Please enter new password.</span>
              </div> 
                <form [formGroup]="passwordForm">
                  <div class="inputs d-flex flex-row justify-content-center mt-2"> 
                    <input class="m-2 text-center form-control rounded" style="width:240px" (focusout)="p1Out()" (keyup)="comparePassowrds()" formControlName="password" type="password" placeholder="Enter password" #password/> 
                  </div>
                  <div class="inputs d-flex flex-row justify-content-center mt-2"> 
                    <input class="m-2 text-center form-control rounded" style="width:240px" [readonly]="p2" (focusout)="p2Out()" (keyup)="comparePassowrds()" formControlName="password_confirmation" type="password" placeholder="Re-enter password" #password2/> 
                  </div>
                  <div *ngIf="_passworderror">
                    <div class="form-error">
                      {{passworderror}}
                    </div>
                  </div> 
                  <div class="mt-4"> 
                    <button [disabled]="!enableP" (click)="reset()"  class="btn btn-warning px-4 validate">Reset</button> 
                  </div> 
                </form>
            </div>
            <div *ngIf="!emailed">
              <div> 
                  <span>Please enter your e-mail address.</span>
              </div> 
                <form [formGroup]="emailForm">
                  <div class="inputs d-flex flex-row justify-content-center mt-2"> 
                    <input class="m-2 text-center form-control rounded" style="width:240px" formControlName="email" type="email" #email/> 
                  </div>
                  <div *ngIf="_emailerror">
                    <div class="form-error">
                      {{emailerror}}
                    </div>
                  </div> 
                  <div class="mt-4"> 
                    <button [disabled]="!emailForm.valid" (click)="ok()"  class="btn btn-warning px-4 validate">Ok</button> 
                  </div> 
                </form>
            </div>
            <div *ngIf="emailed && !canReset">
              <h6>Please enter verification code</h6> 
              <div> 
                <span>A code has been sent to your e-mail address </span>
              </div> 
              <form [formGroup]="codeForm">
              <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2"> 
                <input (keyup)="nextNum1()" (keyup.arrowright)="nextNum1()" class="m-2 text-center form-control rounded" formControlName="num1" #num1 type="text" maxlength="1" id="first" oninput="this.value=this.value.replace(/[^0-9]/g,'');" /> 
                <input (keyup)="nextNum2()" (keyup.arrowright)="nextNum2()" (keyup.arrowleft)="initNum()" class="m-2 text-center form-control rounded" formControlName="num2" #num2 type="text" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" /> 
                <input (keyup)="nextNum3()" (keyup.arrowright)="nextNum3()" (keyup.arrowleft)="nextNum1()" class="m-2 text-center form-control rounded" formControlName="num3" #num3 type="text" id="third" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" /> 
                <input (keyup)="nextNum4()" (keyup.arrowright)="nextNum4()" (keyup.arrowleft)="nextNum2()" class="m-2 text-center form-control rounded" formControlName="num4" #num4 type="text" id="fourth" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" /> 
              </div> 
              <div class="mt-4"> 
                <button [disabled]="!enableB" (click)="verify()" class="btn btn-warning px-4 validate">Verify</button> 
              </div> 
              </form>
            </div>
            <div style="padding-top: 5px;"> 
              <span>{{message}}</span>
            </div>
          </div> 
          <div class="card-2" *ngIf="attempts<3"> 
            <div class="content d-flex justify-content-center align-items-center"> 
            <span>Didn't get the code</span> <a (click)="reSend()" class="text-decoration-none ms-3"> &nbsp;Resend({{attempts}}/3)</a> 
            </div> 
          </div> 
        </div>
        <div *ngIf="canLogin" class="position-relative"> 
          <div class="card p-2 text-center"> 
            <h5>Verify your account</h5> 
           
            
            <div style="padding-top: 5px;"> 
              <span>{{message}}</span>
            </div>
            <div class="mt-4"> 
              <button (click)="goLogin()" class="btn btn-warning px-4 validate">Ok</button> 
            </div> 
          </div> 
          
        </div>
      </div>

      <ng-template #loading let-modal>
    
        <div class="modal-body center">
            <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
            <div class="spacer20"></div>
            <p class="processing">Working...</p>
        </div>
              
    </ng-template>

    <ng-template #redirect let-modal>
    
      <div class="modal-body center">
          <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
          <div class="spacer20"></div>
          <p class="processing">{{redirectmessage}}</p>
          <!-- <p class="progressing">{{_submitting}}</p> -->
      </div>
    </ng-template>
