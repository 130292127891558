import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  showmenu=true;
  loggedIn:boolean;
  avatarpresent$: Observable<boolean>;
  avatarname$: Observable<string>;
  avatarinitials$: Observable<string>;
  id:string;
  avatarname: string;
  initial: string;
  c:any;
  error:any=null;
  shownav:boolean;

  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private dataService:DataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authService.authStatus.subscribe(value => this.loggedIn = value);
    this.dataService._shownav.subscribe(value => 
      this.shownav = value,
      );
   
    //Perform the below to retrieve user avatar / initials when the user is logged on and the navbar is loading
    //The observable values, would have not been set from anywhere else
    if (this.loggedIn){
      this.id=localStorage.getItem('id');
      this.initial=localStorage.getItem('initial');
      this.dataService.getAvatarinitials(this.initial);
      //this.getProfilebyuserid();
      }
    //this.dataService.avatar.subscribe(value => this.avatarname = value);
    //this.avatarname="avatars/CoimMn1eptSjSzKdRwrPFX8XicUOhwIRCHUMbedn.jpeg";
    this.avatarname$=this.dataService.avatar;
    this.avatarpresent$=this.dataService.avatarpresent;
    console.log('INITIAL'+this.initial);
    this.avatarinitials$=this.dataService.initials; 

  }

  logOut(event: MouseEvent){
    event.preventDefault();
    this.tokenService.remove();
    this.authService.changeAuthStatus(false);
    this.dataService.resetRoles();
    this.dataService.resetAvatar();
    this.dataService.setRegistered(false);
    this.router.navigateByUrl('/login');
  }

}
