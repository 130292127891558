import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Observable } from 'rxjs';
import {  } from '@fortawesome/free-brands-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ModelService } from '../models/model.service';
import {ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('prof') prof;
  avatarpresent$: Observable<boolean>;
  avatarname$: Observable<string>;
  avatarinitials$: Observable<string>;
  profForm: FormGroup;
  userid:any;
  business:any;
  user:any;
  bizname:any;
  bizservice:string;
  bizlocation:string;
  faUser = faUser;
  faUsers = faUsers;
  faBriefcase = faBriefcase;
  faReceipt = faReceipt;
  faCog = faCog;
  faTimescircle = faTimesCircle;
  name:string;
  surname:string;
  currentDate=new Date;
  canSave=false;
  message:string;

  constructor(
    private dataService:DataService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private modelService: ModelService,
    private cdref: ChangeDetectorRef,
    private router: Router,
  ) { 
    this.user=this.modelService.initUser();
    console.log('USR='+JSON.stringify(this.user));
  }
  

  ngOnInit(): void {
    /*this.dataService.setuser.subscribe(value => 
      {
        this.userid=value.userid;
        console.log('USR='+JSON.stringify(this.userid));
        this.getBusines();
      }); */ 
      this.avatarname$=this.dataService.avatar;
    this.avatarpresent$=this.dataService.avatarpresent;
    //console.log('INITIAL'+this.initial);
    this.avatarinitials$=this.dataService.initials; 
    this.userid=localStorage.getItem('userid'); 
    this.name=localStorage.getItem('username'); 
    this.surname=localStorage.getItem('usersurname'); 
      //console.log('USR='+JSON.stringify(this.userid));
      this.getBusiness();
      
  }

  createProfform(){
    this.profForm = this.formBuilder.group({
      name: [this.user.name, {validators:[Validators.required], updateOn: 'change'}],
      surname: [this.user.surname, {validators:[Validators.required], updateOn: 'change'}],
      email: [this.user.email,[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
    console.log('FORM-CREATED')
  }


  getBusiness(){
    this.dataService.getBusinessbyuserid(this.userid).subscribe((res)=>{
      this.business=res;
      this.bizname=this.business[0].name;
      this.bizservice=this.business[0].service;
      this.bizlocation=this.business[0].location;
      //console.log('BNAME='+this.bizname);
      //console.log('B='+JSON.stringify(this.business));
      
      },
    errors =>  
      {
       
      }
      );
  }

  saveProf(){
    var p = {"id":this.userid, "name":this.profForm.controls.name.value, "surname":this.profForm.controls.surname.value};
    this.dataService.updateUserprofile(p).subscribe((res)=>{
      this.user=res;
      this.user=this.user.data;
      //console.log('BNAME='+this.bizname);
      console.log('U='+JSON.stringify(this.user));
      this.profForm.markAsPristine();
      this.message="Updated!!";
      localStorage.setItem('username',this.user.name);
      localStorage.setItem('usersurname',this.user.surname);
      this.name=this.user.name; 
      this.surname=this.user.surname; 
      },
    errors =>  
      {
       
      }
      );
  }

  getUser(){
    this.dataService.getUserbyid(this.userid).subscribe((res)=>{
      this.user=res[0];
      console.log('USER='+JSON.stringify(this.user));
      setTimeout(()=>{
        this.createProfform();
      }, 200);
      
      },
    errors =>  
      {
       
      }
      );
  }

  myProf(){
    this.getUser();
    setTimeout(()=>{
      this.openModal(this.prof);
    }, 200);
  }

  captureBusiness(){
    this.router.navigateByUrl('/capture');

  }

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }
  

}
