<div class="container">

    <div class="row col-lg-12" style="padding: 0;">
    <div class="col-lg-6 register-panel" style="border-bottom-left-radius: 10px;">
        <div style="vertical-align:middle">
            <p style="font-size: 28px; font-weight: 600; text-align: center;">SMMEWEB.</p>
            <p style="font-size: 20px; font-weight: 300; text-align: center;">An Online Database of Local Businesses</p>
        </div>
    </div>
    <div class="col-lg-6" style="padding-left: 50px; background-color: #ececec; padding-bottom: 20px; border-bottom-right-radius: 10px;">
    <form [formGroup]="userForm">
        <div class="row col-lg-12 no-left-padding" style="margin-top:20px; text-align:left; margin-left: 0px;">
            <div class="row col-lg-12 no-left-padding" style="padding-top: 20px; padding-bottom: 10px; ">
                
                <div class="col-sm-*">
                    Already have an account  | <a  routerLink="/login">&nbsp;LOGIN</a>
                </div>
            </div>
            <div class="row col-lg-12 no-left-padding" style="padding-top: 20px; padding-bottom: 10px; ">
                
                <div class="col-sm-*">
                    <span class="step-no" style="color: #ff8a04;font-weight: 500;font-size: 14px;">STEP 01 of 2&nbsp;</span><br><span class="step-title" style="color: #263A3D;font-weight: 400;font-size: 24px;">JOIN US</span>
                </div>
            </div>
           
           
            <div class="row col-lg-12 no-left-padding">
                <div class="row col-lg-12">
                <div class="row col-lg-12">
                <mat-form-field>
                    <mat-label>E-mail Address *</mat-label>
                    <input #email (focusout)="checkEmail()" matInput formControlName="email" placeholder="Enter your e-mail address">
                </mat-form-field>
                </div>
                <div *ngIf="error">
                    <div class="form-error" [hidden]="!error.email">
                      {{error.email}}
                    </div>
                </div>
                <div *ngIf="_emailerror">
                    <div class="form-error">
                      {{emailerror}}
                    </div>
                  </div>
                </div>
                <div class="row col-lg-12">
                <div class="row col-lg-12">
                    <mat-form-field >
                    <mat-label>Password *</mat-label>
                    <input [readonly]="p1" (focusout)="p1Out()" (keyup)="comparePassowrds()" matInput type="password" formControlName="password" placeholder="Enter password">
                </mat-form-field>
                </div>
                <div *ngIf="error" class="row col-lg-12">
                    <div class="form-error" [hidden]="!error.password">
                      {{error.password}}
                    </div>
                </div>
                
                </div>
                <div class="row col-lg-12">
                    <div class="row col-lg-12">
                    <mat-form-field>
                        <mat-label>Confirm Password *</mat-label>
                        <input [readonly]="p2" (focusout)="p2Out()" (keyup)="comparePassowrds()" matInput type="password" formControlName="password_confirmation" placeholder="Enter password">
                    </mat-form-field>
                    </div>
                    <div *ngIf="_passworderror" class="row col-lg-12">
                        <div class="form-error">
                          {{passworderror}}
                        </div>
                    </div>
                </div>
            </div>
         
        </div>
      
        <div class="row col-lg-12 no-left-padding">
            <div class="row col-lg-12">
                <button class="btn btn-warning btn-lg col-sm-6" [disabled]="!enableU" mat-raised-button color="primary" (click)="registerUser()">Register</button>
            </div>
        </div>
      </form>
      <!--<div class="row col-lg-12 no-left-padding">
        <div class="col col-lg-5 no-left-padding">
            <hr />
        </div>
        <div class="col col-lg-2 ">
            <small style="color: #a1a1a1; padding:0px; margin:0px; text-align: center;">OR</small>
        </div>
        <div class="col col-lg-5 no-left-padding">
            <hr />
        </div>
      </div>
           
      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-*">
          <button type="submit" (click)="signInWithGoogle()" style="font-size: 12px;" class="btn btn-warning btn-lg col-sm-12"><fa-icon [icon]="faGoogle" [styles]="{'color': '#839192', 'font-size':'14px'}"></fa-icon> | Sign up with Google</button>
        </div>
        
    </div>-->
    </div>
    </div>
</div>
<ng-template #redirect let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">{{redirectmessage}}</p>
        <!-- <p class="progressing">{{_submitting}}</p> -->
    </div>
  </ng-template>
  <ng-template #already let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Already a member, logging you in...</p>
        <!-- <p class="progressing">{{_submitting}}</p> -->
    </div>
  </ng-template>

  <ng-template #submitting let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Submitting...</p>
        <!-- <p class="progressing">{{_submitting}}</p> -->
    </div>
    
   
  </ng-template>
