<div class="container" style="margin-top: 100px;">
    <div class="row col-lg-12 ">
        <button style="width: 200px; margin-left: auto; margin-right: 0;" mat-raised-button color="primary" (click)="yourCard()">Your Digital Card</button>
    </div>
    <form *ngIf="businessForm" [formGroup]="businessForm">
        <div class="row col-lg-12 ">
            <div class="row col-lg-12" style="padding-top: 20px; padding-bottom: 10px;">
                <div class="col-sm-* ">
                    <span class="step-title" style="color: #263A3D;font-weight: 400;font-size: 24px;">YOUR BUSINESS</span>
                </div>
            </div>
            <div class="row col-lg-12">
                <mat-form-field>
                    <mat-label>Business Name *</mat-label>
                    <input matInput formControlName="name" placeholder="Name of Business" maxlength="50">
                </mat-form-field>
            </div>
            <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label>Service / Product *</mat-label>
                    <mat-select name="service" formControlName="service">
                        <mat-option *ngFor="let s of services" [value]="s.viewValue">
                          {{s.viewValue}}
                        </mat-option>
                    </mat-select>
                    <!-- <input matInput formControlName="website" placeholder="What does your business do?"> -->
                </mat-form-field>
                </div>
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label>Location *</mat-label>
                    <mat-select name="location" formControlName="location">
                        <mat-option *ngFor="let l of locations" [value]="l.viewValue">
                          {{l.viewValue}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
            </div>
            <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label>Name *</mat-label>
                    <input matInput formControlName="contactname" placeholder="Name of a contact person" maxlength="20">
                </mat-form-field>
                </div>
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label>Surname *</mat-label>
                    <input matInput formControlName="surname" placeholder="Surname of a contact person" maxlength="20">
                </mat-form-field>
                </div>
            </div>
         
            <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label>Contact Number *</mat-label>
                    <input matInput formControlName="tel" placeholder="Business contact number" maxlength="13" type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                </mat-form-field>
                </div>
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label>E-mail Address *</mat-label>
                    <input matInput formControlName="email" placeholder="Business E-mail Address" maxlength="50"  type="email">
                </mat-form-field>
                </div>
            </div>
        
            <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label>Website Address</mat-label>
                    <input matInput formControlName="website" placeholder="Business Website Address, if available" maxlength="50">
                </mat-form-field>
                </div>
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label>Facebook Page</mat-label>
                    <div class="row col-lg-12">
                    <span style="display: table">
                        <label style="padding:0px; margin:0px; margin-top: 10px; font-size: 20px;font-weight: 300;">{{fb}}</label>
                    </span>
                    <div class="col">
                        <input (focusin)="fbin()" (focusout)="fbout()" matInput formControlName="fbpage" placeholder="Business facebook page" maxlength="20">
                    </div>
                    </div>
                </mat-form-field>
                </div>
            </div>
            <div class="row col-lg-12">
                <mat-form-field>
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="description" placeholder="Let us know more about the services/products you offer" maxlength="200"></textarea>
                </mat-form-field>
            </div>
         
          
        </div>
      
        <div>
          <button mat-raised-button color="primary" [disabled]="!businessForm.valid" (click)="updateBusiness()">Update</button>
        </div>
      </form>
</div>
