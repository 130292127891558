<div class="container">
    <div class="row col-lg-12" style="padding: 0;">
        <div class="col-lg-6 login-panel" style="border-bottom-left-radius: 10px;">
            <div style="vertical-align:middle">
                <p style="font-size: 28px; font-weight: 600; text-align: center;">SMMEWEB.</p>
                <p style="font-size: 20px; font-weight: 300; text-align: center;">An Online Database of Local Businesses</p>
            </div>
        </div>
        <div class="col-lg-6" style="padding-left: 50px; background-color: #ececec; padding-bottom: 20px; border-bottom-right-radius: 10px;">
         
            <div class="row col-lg-12 no-left-padding" style="margin-top: 20px;margin-bottom: 40px; ; margin-left: 0px;">
                <div class="row col-lg-12 no-left-padding" style="padding-top: 20px; padding-bottom: 10px; ">
                    <span style="color: #263A3D;font-weight: 400;font-size: 24px;">LOGIN</span>
                </div>
                <div class="row no-left-padding" style="margin-top: 10px;">
                    <div class="col-lg-12 no-left-padding">
                      <button type="submit" (click)="signInWithGoogle()" style="font-size: 12px;" class="btn btn-warning btn-lg col-sm-12"><fa-icon [icon]="faGoogle" [styles]="{'color': '#839192', 'font-size':'14px'}"></fa-icon> Using a Google account</button>
                    </div>
                    <div class="row col-lg-12 " style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="col col-lg-5 ">
                            <hr />
                        </div>
                        <div class="col col-lg-2 ">
                            <small style="color: #a1a1a1; padding:0px; margin:0px; text-align: center;">OR</small>
                        </div>
                        <div class="col col-lg-5 no-left-padding">
                            <hr />
                        </div>
                  </div>
                <form [formGroup]="loginForm">
                    <div class="row col-lg-12">
                    <mat-form-field>
                        <mat-label>E-mail Address</mat-label>
                        <input matInput formControlName="email" placeholder="Enter user e-mail address">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password" placeholder="Enter password">
                    </mat-form-field>
                    <div *ngIf="error">
                        <div class="form-error">
                          {{errormessage}}
                        </div>
                    </div>
                
                    <div class="row col-lg-12" style="margin-top: 10px;">
                        <button class="btn btn-warning btn-lg col-sm-6" mat-raised-button color="primary" [disabled]="!loginForm.valid" (click)="signIn()">Log In</button>
                    </div> 
                    <div class="col-lg-12 link" style="margin-top: 15px; text-align: right;">
                        <a (click)="forgotPassword()">Forgot password?</a>
                    </div>   
                </div>
                </form>
            </div>
        </div> 
      </div>
</div>

<ng-template #loading let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Verifying...</p>
    </div>
    <!--<div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
      </div>-->
   
</ng-template>
