import { Component, OnInit , ViewChild} from '@angular/core';
import { DataService } from '../../services/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mybusiness',
  templateUrl: './mybusiness.component.html',
  styleUrls: ['./mybusiness.component.css']
})
export class MybusinessComponent implements OnInit {
  @ViewChild('submitting') submitting;
  business:any;
  userid:any;
  businessForm: FormGroup;
  fb="";
  error:any;
  services: any[] = [
    {value: '0', viewValue: 'IT'},
    {value: '1', viewValue: 'Marketing'},
    {value: '2', viewValue: 'Electrical'},
    {value: '3', viewValue: 'Painting'},
    {value: '4', viewValue: 'Building Contractor'}
  ];

  locations: any[] = [
    {value: '0', viewValue: 'KZN Lower South Coast'},
    {value: '1', viewValue: 'Scottburgh'},
    {value: '2', viewValue: 'Durban'},
    {value: '3', viewValue: 'Pietermaritzburg'},
    {value: '4', viewValue: 'Richards Bay'}
  ];

  constructor(
    private dataService:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.userid=localStorage.getItem('userid');
    this.getBusiness();
   
  }

  createBusinessform(){
    this.businessForm = this.formBuilder.group({
      name: [this.business.name, Validators.required],
      contactname: [this.business.contactname, Validators.required],
      surname: [this.business.surname, Validators.required],
      service: [this.business.service, Validators.required],
      location: [this.business.location, Validators.required],
      tel: [this.business.tel, Validators.required],
      email: [this.business.email, [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
     // website: [this.business.website, [Validators.required,Validators.pattern('^https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')]],
      website: [this.business.website, [Validators.pattern('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')]],
      fbpage: [this.business.fbpage],
      description: [this.business.description],
    });
    if(!this.businessForm.controls.fbpage.value){
      this.fb="";
      
    }else{
        this.fb="www.facebook.com/";
      }
  }

  getBusiness(){
    this.dataService.getBusinessbyuserid(this.userid).subscribe((res)=>{
      this.business=res;
      this.business=this.business[0];
      this.createBusinessform();
      //console.log('B='+JSON.stringify(this.business));
      
      },
    errors =>  
      {
       
      }
      );
  }

  updateBusiness(){
    this.openModal(this.submitting);
    this.business.name=this.businessForm.controls.name.value;
    this.business.contactname=this.businessForm.controls.contactname.value;
    this.business.surname=this.businessForm.controls.surname.value;
    this.business.service=this.businessForm.controls.service.value;
    this.business.location=this.businessForm.controls.location.value;
    this.business.tel=this.businessForm.controls.tel.value;
    this.business.email=this.businessForm.controls.email.value;
    this.business.website=this.businessForm.controls.website.value;
    this.business.fbpage=this.businessForm.controls.fbpage.value;
    this.business.description=this.businessForm.controls.description.value;
    var b = {"id":this.business.id, "name":this.business.name, "website":this.business.website, "email":this.business.email,
    "fbpage":this.business.fbpage,"description":this.business.description, "location":this.business.location,"tel":this.business.tel,
    "contactname":this.business.contactname, "surname":this.business.surname, "service":this.business.service
  }
  console.log('B='+JSON.stringify(b));
    this.dataService.updateBusiness(JSON.stringify(b))
    .subscribe((res)=>{
    this.business=res;
    this.business=this.business.data;
    console.log('BIZ='+JSON.stringify(res));
     this.valaModal();
  },
  errors =>  
  {
    this.valaModal();
    this.error = errors.error.errors;
    //window.scrollTo(0,0);
  }
);
}

  fbin(){
    this.fb="www.facebook.com/";
  }
  
  fbout(){
    if(!this.businessForm.controls.fbpage.value){
      this.fb="";
    }
   
  }

  yourCard(){
    this.router.navigate(['/card/',this.business.id]);

  }

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  valaModal(){
    this.modalService.dismissAll();
  }


}
