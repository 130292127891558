<div *ngIf="business">
<header id="header" class="header" style="padding-top: 0px; padding-bottom: 0px; margin: 0px; background: none;">
    <div class="row col-lg-12" style="background-color: brown; margin: 0px; padding-top: 10px;" [ngStyle]="{'background-color': hbgcolor}">
            <div *ngIf="business.avatar" class="row col-lg-12">
                <div *ngIf="business.avatar"  style="margin-top: 0rem;">
                    <img style="border-radius: 50%; height: 250px; width: 250px; border: 0px solid #fff;" class="img-fluid" src="{{address}}{{business.avatar}}" alt="alternative" />
                </div> 
                <div *ngIf="!business.avatar"  style="margin-top: 0rem;">
                    <img style="border-radius: 50%; height: 250px; width: 250px; border: 0px solid #fff;" class="img-fluid" src="assets/images/img_placeholder_avatar.jpg" alt="alternative" />
                </div>
            </div>
            <div *ngIf="business.logo" class="row col-lg-12" style="margin-top: -6rem; margin-left: 6rem;">
                <div *ngIf="business.logo"  style="margin-top: 0rem;">
                    <img style="border-radius: 50%; height: 100px; width: 100px; border: 0px solid #fff;" class="img-fluid" src="{{address}}{{business.logo}}" alt="alternative" />
                </div> 
                <div *ngIf="!business.logo"  style="margin-top: 0rem;">
                    <img style="border-radius: 50%; height: 100px; width: 100px; border: 0px solid #fff;" class="img-fluid" src="assets/images/img_placeholder_avatar.jpg" alt="alternative" />
                </div>
            </div> 
            <div class="row col-lg-12">
                <div class="text-container" style="padding: 0.4rem;">
                    <span style="font-size: 1.8rem; line-height: 1.8rem; color: #fff; display: block; margin-bottom: 0.4rem; font-weight: 400;">{{business.contactname}} {{business.surname}}</span>
                    <span style="font-size: 1.2rem; line-height: 1.2rem; color: #d0d0d0;display: block;margin-bottom: 0.4rem; font-style: italic;">{{business.contactjobtitle}}</span>
                    <span style="font-size: 1.2rem; line-height: 1.2rem; color: #fff;display: block; font-weight:500;">{{business.name}}</span>
                </div>
            </div> 
            <div *ngIf="business.contactbrief" class="row">
                <div class="text-container" style="padding: 1rem; padding-top: 0.2rem;">
                    <span style="font-size: 1.2rem; line-height: 1.2rem; color: #f7f7f7; display: block; text-align: center;">{{business.contactbrief}}</span>
                </div>
            </div>
           
        <div class="row" style="height: 3rem;"></div>
    </div> 
    <div class="row col-lg-12" style="padding:0px; margin:0px;">
        <div class="col col-lg-4 small-card" style="margin-left: 0.5rem;">
            <span style="font-size: 0.8rem; color: #0092ff;display: block;">CALL</span>
            <a href="tel:{{business.contactnumber}}"><fa-icon [icon]="faPhoneSquare" size="3x" [styles]="{'color': '#0092ff'}"></fa-icon></a>

        </div>
        <div class="col col-lg-4 small-card">
            <span style="font-size: 0.8rem; color: #0092ff;display: block;">E-MAIL</span>
            <a href="mailto:{{business.contactemail}}"><fa-icon [icon]="faEnvelope" size="3x" [styles]="{'color': '#0092ff'}"></fa-icon></a>

        </div>
        <div class="col col-lg-* small-card" style="margin-right: 0.5rem; " (click)="prepareContact()">
            <span style="font-size: 0.8rem; color: #0b8606;display: block; font-weight: 500;">SAVE CONTACT</span>
            <a><fa-icon [icon]="faCloudDownloadAlt" size="3x" [styles]="{'color': '#0b8606'}"></fa-icon></a>

        </div>
    </div>
</header>

<div *ngIf="business.businessbrief" class="row col-lg-12" style="margin-top: 2rem;">
   
    <div class="row col-lg-12" style="margin-bottom: 10px;">
        <span style="font-size: 1.6rem; line-height: 2.0rem; color: #363636;display: block; font-weight:400;"><fa-icon [icon]="faInfoCircle" size="1.2x" [styles]="{'color': '#a0a0a0'}"></fa-icon>&nbsp;About the business</span>
    </div>
    <div class="row col-lg-12">
        <span style="font-size: 1.4rem; line-height: 1.6rem; color: #7b7b7b;display: block; font-weight:300;">{{business.businessbrief}}</span>
    </div>
</div>

<div *ngIf="business.image1 || business.image2 || business.image3 || business.image4" class="row col-lg-12" style="margin-top: 2rem;">
    <div class="row col-lg-12" style="margin-bottom: 10px;">
        <span style="font-size: 1.6rem; line-height: 2.0rem; color: #363636;display: block; font-weight:400;"><fa-icon [icon]="faWork" size="1.2x" [styles]="{'color': '#a0a0a0'}"></fa-icon>&nbsp;Some of what we do</span>
    </div>
    <div *ngIf="business.avatar" class="image-container" style="margin-top: 0rem; margin-bottom: 0.5rem;">
        <img style="height: 250px; width: 380px;" src="{{address}}{{business.image1}}" alt="alternative" />
    </div> 
    <div *ngIf="business.avatar" class="image-container" style="margin-top: 0rem; margin-bottom: 0.5rem;">
        <img style="height: 250px; width: 380px;" src="{{address}}{{business.image2}}" alt="alternative" />
    </div> 
    <div *ngIf="business.avatar" class="image-container" style="margin-top: 0rem; margin-bottom: 0.5rem;">
        <img style="height: 250px; width: 380px;" src="{{address}}{{business.image3}}" alt="alternative" />
    </div> 
    <div *ngIf="business.avatar" class="image-container" style="margin-top: 0rem; margin-bottom: 0.5rem;">
        <img style="height: 250px; width: 380px;" src="{{address}}{{business.image4}}" alt="alternative" />
    </div> 
</div>

<div class="row col-lg-12" style="margin-top: 2rem;">
    <div class="row col-lg-12" style="margin-bottom: 10px;">
        <span style="font-size: 1.6rem; line-height: 2.0rem; color: #363636;display: block; font-weight:400;"><fa-icon [icon]="faMobile" size="1.2x" [styles]="{'color': '#a0a0a0'}"></fa-icon>&nbsp;Get in touch</span>
    </div>
    <div class="row col-lg-12 contact-section" >
        <span style="font-size: 1.2rem; line-height: 1.2rem; color: #a0a0a0;display:flex; font-weight: 300; font-style: italic;">Mobile</span>
        <span style="font-size: 1.4rem; color: #a0a0a0;display:flex;"><a href="tel:{{business.contactnumber}}">{{business.contactnumber}}</a></span>
    </div>
    <div class="row col-lg-12 contact-section">
        <span style="font-size: 1.2rem; line-height: 1.2rem; color: #a0a0a0;display:flex; font-weight: 300; font-style: italic;">Work</span>
        <span style="font-size: 1.4rem; color: #a0a0a0;display:flex;"><a href="tel:{{business.tel}}">{{business.tel}}</a></span>
    </div>
    <div class="row col-lg-12 contact-section">
        <span style="font-size: 1.2rem; line-height: 1.2rem; color: #a0a0a0;display:flex; font-weight: 300; font-style: italic;">E-mail</span>
        <span style="font-size: 1.4rem; color: #a0a0a0;display:flex;"><a href="mailto:{{business.contactemail}}">{{business.contactemail}}</a></span>
    </div>
    <div class="row col-lg-12 contact-section">
        <span style="font-size: 1.2rem; line-height: 1.2rem; color: #a0a0a0;display:flex;font-weight: 300; font-style: italic;">Website</span>
        <span style="font-size: 1.4rem; color: #a0a0a0;display:flex;"><a href="https://asaponline.co.za/">{{business.website}}</a></span>
    </div>
    <div class="row col-lg-12" style="padding-left: 1.0rem; margin-bottom: 0.5rem; margin-top: 1.0rem;">
        <span style="font-size: 1.4rem; line-height: 1.8rem; color: #636363;display:flex; font-weight: 400;">Address</span>
        <span style="font-size: 1.4rem; line-height: 1.4rem; color: #a0a0a0;display:flex; font-weight: 300;">{{business.rstreet | titlecase}}</span>
        <span style="font-size: 1.4rem; line-height: 1.4rem; color: #a0a0a0;display:flex; font-weight: 300;">{{business.rsuburb | titlecase}}</span>
        <span style="font-size: 1.4rem; line-height: 1.4rem; color: #a0a0a0;display:flex; font-weight: 300;">{{business.rtown | titlecase}}</span>
        <span style="font-size: 1.4rem; line-height: 1.4rem; color: #a0a0a0;display:flex; font-weight: 300;">{{business.rprovince | titlecase}}</span>
    </div>
    
</div> 
<div *ngIf="business.fbpage || business.twitterpage || business.instapage" class="row col-lg-12" style="margin-top: 2rem;">
    <div class="row col-lg-12">
        <span style="font-size: 1.6rem; line-height: 1.6rem; color: #363636;display: block; font-weight:400;">Social links</span>
    </div>
    <div *ngIf="business.contactnumber" class="row col-lg-12" style="padding-left: 1.0rem; background-color: #25D366; border-radius: 5px; height: 70px; margin: 2px; padding: 4px;">
        <div class="col col-lg-4" style="height: 70px; padding: 2px; text-align: right; padding-right: 3rem;">
            <a href="https://wa.me/{{business.contactnumber}}"><fa-icon [icon]="faWhatsapp" size="4x" [styles]="{'color': '#fff'}"></fa-icon></a>
        </div>
        <div class="col col-lg-8" style="height: 70px; padding-top: 5px;">
            <a href="https://wa.me/{{business.contactnumber}}"><span style="font-size: 2.2rem; color: #fff;">WhatsApp</span><br>
            <span style="font-size: 1.2rem; color: #fff;">Chat to us</span></a>
        </div>
       
    </div>
    <div *ngIf="business.fbpage" class="row col-lg-12" style="padding-left: 1.0rem; background-color: #3b5998; border-radius: 5px; height: 70px; margin: 2px; padding: 4px;">
        <div class="col col-lg-4" style="height: 70px; padding: 2px; text-align: right; padding-right: 3rem;">
            <a href="https://facebook.com/{{business.fbpage}}"><fa-icon [icon]="faFacebook" size="4x" [styles]="{'color': '#fff'}"></fa-icon></a>
        </div>
        <div class="col col-lg-8" style="height: 70px; padding-top: 5px;">
            <a href="https://facebook.com/{{business.fbpage}}"><span style="font-size: 2.2rem; color: #fff;">Facebook</span><br>
            <span style="font-size: 1.2rem; color: #fff;">Follow us</span></a>
        </div>
       
    </div>
    <div *ngIf="business.twitterpage"  class="row col-lg-12" style="padding-left: 1.0rem; background-color: #00acee; border-radius: 5px; height: 70px; margin: 2px; padding: 4px;">
        <div class="col col-lg-4" style="height: 70px; padding: 2px; text-align: right; padding-right: 3rem;">
            <a href="https://twitter.com/{{business.twitterpage}}"><fa-icon [icon]="faTwitter" size="4x" [styles]="{'color': '#fff'}"></fa-icon></a>
        </div>
        <div class="col col-lg-8" style="height: 70px; padding-top: 5px;">
            <a href="https://twitter.com/{{business.twitterpage}}"><span style="font-size: 2.2rem; color: #fff;">Twitter</span><br>
            <span style="font-size: 1.2rem; color: #fff;">Follow us</span></a>
        </div>
       
    </div>
    <div *ngIf="business.instapage"  class="row col-lg-12" style="padding-left: 1.0rem; background-color: #d62976 ; border-radius: 5px; height: 70px; margin: 2px; padding: 4px;">
        <div class="col col-lg-4" style="height: 70px; padding: 2px; text-align: right; padding-right: 3rem;">
            <a href="https://instagram.com/{{business.instapage}}"><fa-icon [icon]="faInstagram" size="4x" [styles]="{'color': '#fff'}"></fa-icon></a>
        </div>
        <div class="col col-lg-8" style="height: 70px; padding-top: 5px;">
            <a href="https://instagram.com/{{business.instapage}}"><span style="font-size: 2.2rem; color: #fff;">Instagram</span><br>
            <span style="font-size: 1.2rem; color: #fff;">Follow us</span></a>
        </div>
       
    </div>
   
</div>



<div class="row">

</div>
</div>


 