<header id="header" class="header">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-xl-5">
              <div class="text-container">
                  <h1 class="h1-large">Create & share digital business cards</h1>
                  <p class="p-large">Digitally share personal and business information easily, say goodbye to the old paper business card for good.</p>
                  <a class="btn-solid-lg" routerLink="/capturecard">Create yours today</a>
              </div>
          </div> 
          <div class="col-lg-6 col-xl-7">
              <div class="image-container">
                  <img class="img-fluid" src="assets/images/header-image.png" alt="alternative" />
              </div> 
          </div> 
      </div> 
  </div> 
</header> 
<div id="services" class="cards-1 bg-gray" style="background-color:#0092ff;">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              
            
              <div class="card" style="text-align: center; border-right: 6px solid #9ccdf3;border-width: 0.1rem; border-radius: 0rem;">
                  <div class="card-icon" style="width: 100%; background-color: #0092ff;">
                      <span class="fas fa-address-card" style="color: #f0f8ff; font-size: 62px;"></span>
                  </div>
                  <div class="card-body">
                      <p style="font-size: 1.4rem; line-height: 1.5rem; color: #f0f8ff;">Create beautiful, digital business cards</p>
                  </div>
              </div>
          
              <div class="card" style="text-align: center;">
                  <div class="card-icon" style="width: 100%; background-color: #0092ff;">
                      <span class="fas fa-file-invoice" style="color: #f0f8ff; font-size: 62px;"></span>
                  </div>
                  <div class="card-body">
                      <p style="font-size: 1.4rem; line-height: 1.5rem; color: #f0f8ff;">Create digital banner for your business or brand</p>
                  </div>
              </div>
            
              <div class="card" style="text-align: center;text-align: center; border-left: 6px solid #9ccdf3;border-width: 0.1rem; border-radius: 0rem;">
                  <div class="card-icon" style="width: 100%; background-color: #0092ff;">
                      <span class="far fa-comments" style="color: #f0f8ff; font-size: 62px;"></span>
                  </div>
                  <div class="card-body">
                      <p style="font-size: 1.4rem; line-height: 1.5rem; color: #f0f8ff;">Easily share your contact or business details</p>
                  </div>
              </div>
              
          </div>
      </div>
  </div>
</div> 
<div id="details" class="basic-1">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-xl-7">
              <div class="image-container">
                  <img class="img-fluid" src="assets/images/details-1.png" alt="alternative" />
              </div> 
          </div> 
          <div class="col-lg-6 col-xl-5">
              <div class="text-container">
                  <div class="section-title">EASILY SHARE YOUR CONTACT DETAILS</div>
                  <h2>No more paper business card exchange</h2>
                  <p>Share your digital business card with a tap on a mobile phone a QR code scan or send a link via WhatsApp, E-mail or any other digital platform.</p>
                  <a class="btn-solid-reg" href="#contact">Get yours today</a>
              </div> 
          </div> 
      </div>
  </div> 
</div>
<div id="details" class="basic-1">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-xl-5">
                <div class="text-container">
                    <span class="header-lg">Network the smart way</span>
                    <div class="spacer20"></div>
                    <p>Get rid of the old paper business card for good. With our digital business card you can easily share your contact details digitally via a tap or scan.</p>
                    <a class="btn-solid-reg" href="#contact">Get yours today</a>
                </div> 
            </div> 
            <div class="col-lg-6 col-xl-7">
                <div class="image-container">
                    <img class="img-fluid" src="assets/images/details-1.png" alt="alternative" />
                </div> 
            </div>
        </div>
    </div> 
  </div>

  <div id="details" class="basic-1">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-xl-7">
                <div class="image-container">
                    <img class="img-fluid" src="assets/images/details-1.png" alt="alternative" />
                </div> 
            </div>
            <div class="col-lg-6 col-xl-5">
                <div class="text-container">
                    <span class="header-lg">Get your business out there</span>
                    <div class="spacer20"></div>
                    <p>Create an easily accesible digital banner for your business, share your business contact details, products and services easily.</p>
                    <a class="btn-solid-reg" href="#contact">Get yours today</a>
                </div> 
            </div> 
          
        </div>
    </div> 
  </div>
  <div class="text-container">
    <div class="container">
    <p style="font-size: 3.4rem; line-height: 1.5rem; color: #181818;">Choose your business card</p>
    </div>
    <div class="spacer30"></div>
</div>
  <div id="services" class="cards-1 bg-gray" style="background-color:#ececec;">
    <div class="container">
        <div class="row">
           
           
            <div class="col-lg-12">
               
              
                <div class="card" style="text-align: center; border-style:solid; border-width: 0.3rem; border-color: #f0f8ff; background-color: #ec7c12; padding: 1rem;">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/plastic-biz-card.jpg" alt="alternative" />
                    </div> 
                    <div class="card-body">
                        <p style="font-size: 1.4rem; line-height: 1.5rem; color: #f0f8ff;">DIGITAL BUSINESS CARD <br> Standard</p>
                        <p style="font-size: 1.0rem; line-height: 1.5rem; color: #2c2c2c;">R300.00</p>
                        <a class="btn-solid-lg" href="#">Order Now</a>
                    </div>
                </div>
            
                <div class="card" style="text-align: center; border-style:solid; border-width: 0.2rem; border-color: #f0f8ff; background-color: #b39d74; padding: 1rem;">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/wooden-biz-card.jpg" alt="alternative" />
                    </div> 
                    <div class="card-body">
                        <p style="font-size: 1.4rem; line-height: 1.5rem; color: #f0f8ff;">DIGITAL BUSINESS CARD <br> Wooden</p>
                        <p style="font-size: 1.0rem; line-height: 1.5rem; color: #2c2c2c;">R700.00</p>
                        <a class="btn-solid-lg" href="#">Order Now</a>
                    </div>
                </div>
              
                <div class="card" style="text-align: center; border-style:solid; border-width: 0.2rem; border-color: #f0f8ff; background-color: #000000; padding: 1rem;">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/metal-biz-card.jpg" alt="alternative" />
                    </div> 
                    <div class="card-body">
                        <p style="font-size: 1.4rem; line-height: 1.5rem; color: #f0f8ff;">DIGITAL BUSINESS CARD <br>Steel</p>
                        <p style="font-size: 1.0rem; line-height: 1.5rem; color: #a7a7a7;">R800.00</p>
                        <a class="btn-solid-lg" href="#">Order Now</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  </div> 
  <div class="basic-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-container">
                    <p class="p-large">Say goodbye to the old way of networking, let us get you up and running with your own digital business card</p>
                    <a class="btn-solid-lg" href="#contact">Get you card today</a>
                </div> 
            </div> 
        </div>
    </div> 
  </div> 

 
<div class="cards-2 bg-gray">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <h2 class="h2-heading">Customer testimonials</h2>
          </div> 
      </div>
      <div class="row">
          <div class="col-lg-12">
              
             
            
              
              
              <div class="card">
                  <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                  <div class="card-body">
                      <p class="testimonial-text">I've always struggled to keep business cards, I never know where to put them and whenever I needed one I found myself without one. The digital business card fits into my wallet and is always there when I need it.</p>
                      <div class="testimonial-author">Mdu Mbhele</div>
                      <div class="occupation">MD, ASAP Online</div>
                  </div>
                  <div class="gradient-floor red-to-blue"></div>
              </div>
            
              
              <div class="card">
                  <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                  <div class="card-body">
                      <p class="testimonial-text">Getting a digital business card was one of the best decisions I ever made for my business, it comes in handy as I can use it again and again, the fact that I was able to link the busines profile to it is a plus for me.</p>
                      <div class="testimonial-author">Thulani Ntini</div>
                      <div class="occupation">Network Engineer, Chwepheshe Computers</div>
                  </div>
                  <div class="gradient-floor blue-to-purple"></div>
              </div>
            
              
              <div class="card">
                  <img class="quotes" src="assets/images/quotes.svg" alt="alternative" />
                  <div class="card-body">
                      <p class="testimonial-text">The digital business card from Profila has really made my life easier, whenever I need to share my details with a client all I need to do is tap my card on their phone and they have access to all my contact details.</p>
                      <div class="testimonial-author">Khulekani Mathe</div>
                      <div class="occupation">Engineer, CONLOG</div>
                  </div>
                  <div class="gradient-floor purple-to-green"></div>
              </div>
            
          </div> 
      </div>
  </div> 
</div> 
<div class="slider-1">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <h4>Some of our clients</h4>
              <hr class="section-divider" />
           
              <div class="slider-container">
                  <div class="swiper-container image-slider">
                      <div class="swiper-wrapper">
                          <div class="swiper-slide">
                              <img class="img-fluid" src="assets/images/customer-logo-1.png" alt="alternative" />
                          </div>
                          <div class="swiper-slide">
                              <img class="img-fluid" src="assets/images/customer-logo-2.png" alt="alternative" />
                          </div>
                          <div class="swiper-slide">
                              <img class="img-fluid" src="assets/images/customer-logo-3.png" alt="alternative" />
                          </div>
                          <div class="swiper-slide">
                              <img class="img-fluid" src="assets/images/customer-logo-4.png" alt="alternative" />
                          </div>
                          <div class="swiper-slide">
                              <img class="img-fluid" src="assets/images/customer-logo-5.png" alt="alternative" />
                          </div>
                          <div class="swiper-slide">
                              <img class="img-fluid" src="assets/images/customer-logo-6.png" alt="alternative" />
                          </div>
                      </div>
                  </div> 
              </div> 
             
          </div> 
      </div>
  </div> 
</div> 

<div id="contact" class="form-1">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <div class="text-container">
                  <div class="section-title">GET IN TOUCH</div>
                  <h2>If you'd like more information, please complete this form</h2>
                  <p>Still no sure how to continue or need just a little bit more information, complete the form and get in touch, one of our consultants will contact you.</p>
              </div> 
          </div> 
          <div class="col-lg-6">
             
              <form>
                  <div class="form-group">
                      <input type="text" class="form-control-input" placeholder="Name" required />
                  </div>
                  <div class="form-group">
                      <input type="email" class="form-control-input" placeholder="Email" required />
                  </div>
                  <div class="form-group">
                      <input type="text" class="form-control-input" placeholder="Phone" required />
                  </div>
                  <div class="form-group">
                      <input type="text" class="form-control-input" placeholder="Message" required />
                  </div>
                  <div class="form-group">
                      <button type="submit" class="form-control-submit-button">Submit</button>
                  </div>
              </form>
            
          </div> 
      </div>
  </div> 
</div>

