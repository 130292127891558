<div class="container">
        <form [formGroup]="businessForm">
            <div class="row col-lg-12 ">
                <div class="row col-lg-12" style="padding-top: 20px; padding-bottom: 10px;">
                    <div class="col-sm-* ">
                        <span class="step-no" style="color: #ff8a04;font-weight: 500;font-size: 14px;">STEP 02 of 2.&nbsp;</span><br><span class="step-title" style="color: #263A3D;font-weight: 400;font-size: 24px;">REGISTER YOUR BUSINESS</span>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <mat-form-field>
                        <mat-label>Business Name *</mat-label>
                        <input matInput formControlName="name" placeholder="Name of Business" maxlength="50">
                    </mat-form-field>
                </div>
                <div class="row col-lg-12 no-left-padding">
                    <div class="col col-lg-6">
                    <mat-form-field>
                        <mat-label>Service / Product *</mat-label>
                        <mat-select name="service" formControlName="service">
                            <mat-option *ngFor="let s of services" [value]="s.viewValue">
                              {{s.viewValue}}
                            </mat-option>
                        </mat-select>
                        <!-- <input matInput formControlName="website" placeholder="What does your business do?"> -->
                    </mat-form-field>
                    </div>
                    <div class="col col-lg-6">
                    <mat-form-field>
                        <mat-label>Location *</mat-label>
                        <mat-select name="location" formControlName="location">
                            <mat-option *ngFor="let l of locations" [value]="l.viewValue">
                              {{l.viewValue}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>
                <div class="row col-lg-12 no-left-padding">
                    <div class="col col-lg-6">
                    <mat-form-field>
                        <mat-label>Name *</mat-label>
                        <input matInput formControlName="contactname" placeholder="Name of a contact person" maxlength="20">
                    </mat-form-field>
                    </div>
                    <div class="col col-lg-6">
                    <mat-form-field>
                        <mat-label>Surname *</mat-label>
                        <input matInput formControlName="surname" placeholder="Surname of a contact person" maxlength="20">
                    </mat-form-field>
                    </div>
                </div>
             
                <div class="row col-lg-12 no-left-padding">
                    <div class="col col-lg-6">
                    <mat-form-field>
                        <mat-label>Contact Number *</mat-label>
                        <input matInput formControlName="tel" placeholder="Business contact number" maxlength="13" type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                    </mat-form-field>
                    </div>
                    <div class="col col-lg-6">
                    <mat-form-field>
                        <mat-label>E-mail Address *</mat-label>
                        <input matInput formControlName="email" placeholder="Business E-mail Address" maxlength="50"  type="email">
                    </mat-form-field>
                    </div>
                </div>
            
                <div class="row col-lg-12 no-left-padding">
                    <div class="col col-lg-6">
                    <mat-form-field>
                        <mat-label>Website Address</mat-label>
                        <input matInput formControlName="website" placeholder="Business Website Address, if available" maxlength="50">
                    </mat-form-field>
                    </div>
                    <div class="col col-lg-6">
                    <mat-form-field>
                        <mat-label>Facebook Page</mat-label>
                        <div class="row col-lg-12">
                        <span style="display: table">
                            <label style="padding:0px; margin:0px; margin-top: 10px; font-size: 20px;font-weight: 300;">{{fb}}</label>
                        </span>
                        <div class="col">
                            <input (focusin)="fbin()" (focusout)="fbout()" matInput formControlName="fbpage" placeholder="Business facebook page" maxlength="20">
                        </div>
                        </div>
                    </mat-form-field>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <mat-form-field>
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description" placeholder="Let us know more about the services/products you offer" maxlength="200"></textarea>
                    </mat-form-field>
                </div>
             
              
            </div>
          
            <div>
              <button mat-raised-button color="primary" [disabled]="!businessForm.valid" (click)="registerBusiness()">Register Business</button>
            </div>
          </form>
</div>

<ng-template #submitting let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Submitting...</p>
        <!-- <p class="progressing">{{_submitting}}</p> -->
    </div>
    
   
  </ng-template>

  <ng-template #loading let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Verifying...</p>
    </div>
    <!--<div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
      </div>-->
   
</ng-template>

