import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DataService } from '../../services/data.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { NgOneTapService } from 'ng-google-one-tap';
import { Business } from '../models/business.model';
import { User } from '../models/user.model';
import { ModelService } from '../models/model.service';
import { Router } from '@angular/router';
//import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('submitting') submitting;
  @ViewChild('email') email;
  @ViewChild('already') already;
  @ViewChild('loading') loading;
  @ViewChild('redirect') redirect;
  businessForm: FormGroup;
  
  faSpinner = faSpinner;
  faUser = faUser;
  faGoogle = faGoogle;
  exists=false;
  p1=true;
  p2=true;
  match=false;
  registered=true;
  errormessage: string;

  public business:Business;
  
  error:any;
  userid:any;
  fb="";

    services: any[] = [
      {value: '0', viewValue: 'IT'},
      {value: '1', viewValue: 'Marketing'},
      {value: '2', viewValue: 'Electrical'},
      {value: '3', viewValue: 'Painting'},
      {value: '4', viewValue: 'Building Contractor'}
    ];

    locations: any[] = [
      {value: '0', viewValue: 'KZN Lower South Coast'},
      {value: '1', viewValue: 'Scottburgh'},
      {value: '2', viewValue: 'Durban'},
      {value: '3', viewValue: 'Pietermaritzburg'},
      {value: '4', viewValue: 'Richards Bay'}
    ];
    private loggedIn: boolean;
    userdetails:any;
    

  constructor(
    private formBuilder: FormBuilder,
    private dataService:DataService,
    private modalService: NgbModal,
    private modelService: ModelService,
    private router: Router,
  ) { 
    this.business=this.modelService.initBusiness();
    
    
  }

  ngOnInit(): void {

    
    /*this.dataService.setuser.subscribe(value => 
        {
          var u = value;
          this.userid=value.userid;
          this.business.email=value.useremail;
          this.business.contactname=value.username;
          this.business.surname=value.usersurname;
          console.log('USR='+JSON.stringify(u));
        }); */
    this.userid=localStorage.getItem('userid'); 
    this.business.email=localStorage.getItem('useremail');  
    this.business.contactname=localStorage.getItem('username'); 
    this.business.surname=localStorage.getItem('usersurname');   
    this.modelService.initUser();
    this.createBusinessform();
   
   
  }

  
fbin(){
  this.fb="www.facebook.com/";
}

fbout(){
  if(!this.businessForm.controls.fbpage.value){
    this.fb="";
  }
 
}
  

  createBusinessform(){
    this.businessForm = this.formBuilder.group({
      name: [this.business.name, Validators.required],
      contactname: [this.business.contactname, Validators.required],
      surname: [this.business.surname, Validators.required],
      service: [this.business.service, Validators.required],
      location: [this.business.location, Validators.required],
      tel: [this.business.tel, Validators.required],
      email: [this.business.email, [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
     // website: [this.business.website, [Validators.required,Validators.pattern('^https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')]],
      website: [this.business.website, [Validators.pattern('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')]],
      fbpage: [this.business.fbpage],
      description: [this.business.description],
    });
  }


 signOut(): void {
}


  registerBusiness(){
    this.business.name=this.businessForm.controls.name.value;
    this.business.contactname=this.businessForm.controls.contactname.value;
    this.business.surname=this.businessForm.controls.surname.value;
    this.business.service=this.businessForm.controls.service.value;
    //this.business.businessname=this.businessForm.controls.businessname.value;
    this.business.location=this.businessForm.controls.location.value;
    this.business.tel=this.businessForm.controls.tel.value;
    this.business.email=this.businessForm.controls.email.value;
    this.business.website=this.businessForm.controls.website.value;
    this.business.fbpage=this.businessForm.controls.fbpage.value;
    this.business.description=this.businessForm.controls.description.value;
    //console.log('B='+JSON.stringify(this.business));
    this.submit();
  }

  submit(){
    console.log('Submitting');
    //this._submitting="SUBMITTING BASIC BUSINESS INFO"
    //this.openModal(this.submitting);
    console.log('W='+JSON.stringify(this.business.website))
    if(this.business.website){
      console.log('IN')
      if(this.business.website.substring(0,1)=="w"){
        console.log('IN2')
        var w="http://"+this.business.website;
        this.business.website=w;
      }
    }
    console.log('W='+w)
    console.log('W2='+this.business.website)
    var b = {"name":this.business.name, "website":this.business.website, "email":this.business.email, "tel":this.business.tel, "fax":this.business.fax, "rstreet":this.business.rstreet, "rsuburb":this.business.rsuburb, "rtown":this.business.rtown, "rcode":this.business.rcode, 
      "pstreet":this.business.pstreet, "psuburb":this.business.psuburb, "ptown":this.business.ptown, "pcode":this.business.pcode,
      "fbpage":this.business.fbpage,"description":this.business.description,
  
      "location":this.business.location, "stage":this.business.stage, "industry":this.business.industry, "subindustry":this.business.subindustry, "tradinglocation":this.business.tradinglocation, "turnover":this.business.turnover, "service":this.business.service, "vision":this.business.vision, 
      "fiveyears":this.business.fiveyears, "clientelle":this.business.clientelle,
  
    "contactname":this.business.contactname, "surname":this.business.surname, "idno":this.business.idno, "contactnumber":this.business.contactnumber, "contactemail":this.business.contactemail, "race":this.business.race, "education":this.business.education,
    
    "regno":this.business.regno, "taxno":this.business.taxno, "sarspin":this.business.sarspin, "vat":this.business.vat, "bbbee":this.business.bbbee, "tourism":this.business.tourism, "cidb":this.business.cidb, "statindustry":this.business.statindustry, "coida":this.business.coida, "sabs":this.business.sabs,
    "iso":this.business.iso, "health":this.business.health, "env":this.business.env, "planning":this.business.planning, "tourismaffiliation":this.business.tourismaffiliation, "businesspermit":this.business.businesspermit,
  
  
    "sharebm":this.business.sharebm, "sharebf":this.business.sharebf, "sharewm":this.business.sharewm, "sharewf":this.business.sharewf, seniorbm:this.business.seniorbm, seniorbf:this.business.seniorbf, seniorwm:this.business.seniorwm,
    "seniorwf":this.business.seniorwf, "staffbm":this.business.staffbm, "staffbf":this.business.staffbf, "staffwm":this.business.staffwm, staffwf:this.business.staffwf, tempbm:this.business.tempbm, tempbf:this.business.tempbf, tempwm:this.business.tempwm, tempwf:this.business.tempwf,
  
    "fin":this.business.fin, "sales":this.business.sales, "business":this.business.business, "hr":this.business.hr, "strat":this.business.strat, "tax":this.business.tax, "marketing":this.business.marketing,
    "presenting":this.business.presenting, "tendering":this.business.tendering, "userid":this.userid, "institutionid":-99
  
    }
  
    this.dataService.addBusiness(JSON.stringify(b))
      .subscribe((res)=>{
        console.log('BIZ='+JSON.stringify(res));
        this.goDashboard();
       this.valaModal();
    },
    errors =>  
    {
      this.valaModal();
      this.error = errors.error.errors;
    }
  );
  }

 

  

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  goDashboard(){
    this.router.navigateByUrl('/dashboard');

  }
  
  valaModal(){
    this.modalService.dismissAll();
  }

}


