<nav *ngIf="shownav" id="navbarExample" class="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
  <div class="container">
    
    
       <a class="navbar-brand logo-text" href="#"><img class="img-fluid" src="assets/images/profilalogo.png" width="140px" height="53px" alt="alternative" /></a> 
      <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
          <ul class="navbar-nav ms-auto navbar-nav-scroll" style="margin-right: 80px;">
              <li class="nav-item active">
                  <a class="nav-link" data-toggle="tab" routerLink="/home">Home</a>
              </li>
              <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Products</a>
                <ul class="dropdown-menu" aria-labelledby="dropdown01">
                    <li><a class="dropdown-item" href="#">Article Details</a></li>
                    <li><div class="dropdown-divider"></div></li>
                    <li><a class="dropdown-item" href="#">Terms Conditions</a></li>
                    <li><div class="dropdown-divider"></div></li>
                    <li><a class="dropdown-item" href="#">Privacy Policy</a></li>
                </ul>
            </li> -->
            <li class="nav-item">
                <a class="nav-link" routerLink="/products" >Products</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/pricing" >Pricing</a>
              </li>

              <li>
                <div *ngIf="(loggedIn)" class="inset">
                  <div *ngIf="(avatarpresent$ | async)" class="inset">
                    <img [src]="avatarname$ | async" class="side-avatar rounded-circle image">
                  </div>
                  <div *ngIf="!(avatarpresent$ | async)" class="inset img-container">
                    <div class="middle-initials round avatar-img">
                      <p placement="right" class="no-bottom-padding" style="font-size:30px;">{{avatarinitials$ | async}}</p>
                    </div>
                  </div>
                </div>
                
              </li>
              <li class="nav-item dropdown" *ngIf="loggedIn">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" routerLink="/dashboard" *ngIf="loggedIn">Dashboard</a>
                    <a class="dropdown-item g_id_signout" href="#" (click)="logOut($event)" *ngIf="loggedIn">Logout</a>
                    
                  </div>
              </li>
              
            
          </ul>
          <span class="nav-item">
              <a class="btn-solid-sm" routerLink="/login" *ngIf="!loggedIn">Login</a>
          </span>
          <span class="nav-item">
            <a class="btn-solid-sm" routerLink="/capturecard" *ngIf="!loggedIn">Create Card</a>
        </span>
      </div> 
  </div> 
</nav>

    <router-outlet></router-outlet>
