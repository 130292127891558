import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { counter } from '@fortawesome/fontawesome-svg-core';
import { _countGroupLabelsBeforeOption } from '@angular/material/core';
import {NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { faMapMarked} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('bizmodal') bizmodal;
  loggedIn:boolean;
  businesses:any;
  errors:any=null;
  faTimescircle = faTimesCircle;
  faLocationArrow = faMapMarked;
  logo="nologo.png";
  shouldNavigate: boolean = false; 
  services: any[] = [
    {value: '0', viewValue: 'IT'},
    {value: '1', viewValue: 'Marketing'},
    {value: '2', viewValue: 'Electrical'},
    {value: '3', viewValue: 'Painting'},
    {value: '4', viewValue: 'Building Contractor'}
  ];

  locations: any[] = [
    {value: '0', viewValue: 'KZN Lower South Coast'},
    {value: '1', viewValue: 'Scottburgh'},
    {value: '2', viewValue: 'Durban'},
    {value: '3', viewValue: 'Pietermaritzburg'},
    {value: '4', viewValue: 'Richards Bay'}
  ];
  searchForm: FormGroup;
  _businesses:number;
  _services:number;
  _industries:number;
  _happy:number;
  s:string;
  l:string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dataService:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this._businesses=50;
    this._services=120;
    this._industries=20;
    this._happy=100;
    this.authService.authStatus.subscribe(value => this.loggedIn = value);
    this.getBusinesses();
    this.createSearchform();
    this.doStats(this._businesses, "businesses");
    this.doStats(this._services, "services");
    this.doStats(this._industries, "industries");
    this.doStats(this._happy, "happy");
    this._businesses=0;
    this._services=0;
    this._industries=0;
    this._happy=0;
    }

    doStats(b,t){
      var b = b;
      
      var that=this;
      //console.log("hello world="+this._businesses);
      for (let i = 1; i < b+1; i++) {
        
        setTimeout(function() {
          that._counter(t)
          
        }, i * 20);
      }
    }

    _counter(t){
      if(t=="businesses")
     { this._businesses++;}
     if(t=="services")
     { this._services++;}
     if(t=="industries")
     { this._industries++;}
     if(t=="happy")
     { this._happy++;}
    }

    createSearchform(){
      this.searchForm = this.formBuilder.group({
        service: [],
        location: [],
      });
    }

    getBusinesses(){
      this.dataService.getBusinesses()
            .subscribe((b) => {
            this.businesses = b;
            //console.log(JSON.stringify(this.businesses))
          },
          errors => {
            this.errors = errors;
          }
          );
    }

    search(){
      this.s = this.searchForm.controls.service.value;
      this.l = this.searchForm.controls.location.value;
      //console.log('L='+l+' s='+s)
      if(this.s && this.l){
        console.log('BOTH');
        this.getBusinessbyls(this.l,this.s);
        this.goBiz();
      }
      if(!this.s && this.l){
        console.log('L');
        //this.getBusinessbylocation(l);
      }
      if(this.s && !this.l){
        console.log('S');
        //this.getBusinessbyservice(s);
      }
      
    }

    getBusinessbyservice(s){
      var s = this.searchForm.controls.service.value;
      //console.log('S='+s)
      this.dataService.getBusinessbyservice(s).subscribe((res)=>{
        this.businesses=res;
        //console.log('B='+JSON.stringify(this.businesses));
        
        },
      errors =>  
        {
         
        }
        );
    }

    getBusinessbylocation(l){
      //console.log('S='+s)
      this.dataService.getBusinessbylocation(l).subscribe((res)=>{
        this.businesses=res;
        //console.log('B='+JSON.stringify(this.businesses));
        
        },
      errors =>  
        {
         
        }
        );
    }

    getBusinessbyls(l,s){
      this.dataService.getBusinessbyls(l,s).subscribe((res)=>{
        this.businesses=res;
       // console.log('B='+JSON.stringify(this.businesses));
        
        },
      errors =>  
        {
         
        }
        );
    }
    

    goBiz(){
      this.dataService.setParameters({s:this.s, l:this.l});
      this.router.navigateByUrl('/listing');
      /*setTimeout(()=>{
        this.openModal(this.bizmodal);
      }, 200);*/
    }

    openModal(f) {
      this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg', centered:true, keyboard: false, windowClass: 'my-class'
      }).result.then((result) => {
      }, (reason) => {
      });
    }

    valaModal(){
      this.modalService.dismissAll();
    }

}
