<header id="pages">
  <div class="page-bg-img" style="background-image: url('../../../images/background2.jpg');">
    <div class="overlay"></div>
  </div>
  
  <div class="home-wrapper">
  <div class="container">
    <div class="row">

      <div class="col-md-12">
        <div class="home-content">
          <h1 class="blue-text" style="font-size: 62px; font-weight: 900;">SMMEWEB.</h1>
          <div class="spacer10"></div>
          <p class="dark-text" style="font-size: 28px; font-weight: 300;">An Online Database of Local Businesses
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</header>
<div class="container">

  <mat-horizontal-stepper labelPosition="bottom" linear #stepper (selectionChange)="headerNext()">
      <mat-step [stepControl]="basicFormGroup" [editable]="isEditable">
        <form [formGroup]="basicFormGroup">
          <ng-template matStepLabel>Basic</ng-template>
          <div class="row col-lg-12">
            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
              Please enter basic business information
          </div>
            <div class="row col-lg-12">
              <mat-form-field>
                <mat-label>Business Name *</mat-label>
                <input matInput formControlName="name" placeholder="Name of Business">
              </mat-form-field>
            </div>
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Website Address</mat-label>
                  <input matInput formControlName="website" placeholder="Business Website Address, if available">
                </mat-form-field>
              </div>
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>E-mail Address</mat-label>
                  <input matInput formControlName="email" placeholder="Business E-mail Address">
                </mat-form-field>
              </div>
            </div>
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Telephone Number</mat-label>
                  <input matInput formControlName="tel" placeholder="Business Contact Number">
                </mat-form-field>
              </div>
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Fax Number</mat-label>
                  <input matInput formControlName="fax">
                </mat-form-field>
              </div>
            </div>
            <div class="spacer30"></div>
            <div class="row col-lg-12 no-left-padding">
              <div class="row col-lg-12">
                <mat-label style="color: green; padding-left: 15px; font-weight: 500;">Physical Address</mat-label>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Street Number</mat-label>
                  <input matInput formControlName="rstreet">
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Suburb</mat-label>
                  <input matInput formControlName="rsuburb">
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Town / City</mat-label>
                  <input matInput formControlName="rtown">
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Code</mat-label>
                  <input matInput formControlName="rcode">
                </mat-form-field>
              </div>
            </div>

            <div class="spacer30"></div>
            <div class="row col-lg-12 no-left-padding">
              <div class="row col-lg-12">
                <mat-label style="color: green; padding-left: 15px; font-weight: 500;">Postal Address</mat-label>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Box / Street Number</mat-label>
                  <input matInput formControlName="pstreet">
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Suburb</mat-label>
                  <input matInput formControlName="psuburb">
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Town / City</mat-label>
                  <input matInput formControlName="ptown">
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Code</mat-label>
                  <input matInput formControlName="pcode">
                </mat-form-field>
              </div>
            </div>
            
           
            
          </div>
        
          <div>
            <button mat-raised-button matStepperNext color="primary" (click)="next()">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="adminFormGroup" [editable]="isEditable">
        <form [formGroup]="adminFormGroup">
          <div class="row col-lg-12">
          <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
            Please enter basic business information
          </div>
          <mat-form-field>
            <mat-label>Business Location</mat-label>
            <mat-select name="location" formControlName="location">
              <mat-option *ngFor="let loc of locations" [value]="loc.viewValue">
                {{loc.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Stage of Business</mat-label>
            <mat-select name="location" formControlName="stage">
              <mat-option *ngFor="let s of stages" [value]="s.viewValue">
                {{s.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Type of Industry</mat-label>
            <mat-select name="location" formControlName="industry" (selectionChange)="loadSub()">
              <mat-option *ngFor="let i of industry" [value]="i.viewValue">
                {{i.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Sub Industry</mat-label>
            <mat-select name="location" formControlName="subindustry">
              <mat-option *ngFor="let s of _subindustry" [value]="s.viewValue">
                {{s.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Current Trading Location</mat-label>
            <mat-select name="location" formControlName="tradinglocation">
              <mat-option *ngFor="let loc of locations" [value]="loc.viewValue">
                {{loc.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Current Turnover</mat-label>
            <mat-select name="location" formControlName="turnover">
              <mat-option *ngFor="let t of turnover" [value]="t.viewValue">
                {{t.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Business Service / Production</mat-label>
            <input matInput formControlName="service" placeholder="Provide description">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Business Vision</mat-label>
             <input matInput formControlName="vision" placeholder="Provide description">
           </mat-form-field>
           <mat-form-field>
             <mat-label>Where do you see your business in 5 years?</mat-label>
             <input matInput formControlName="fiveyears" placeholder="Provide description">
           </mat-form-field>
           <mat-form-field>
             <mat-label>Clientelle</mat-label>
             <mat-select name="location" formControlName="clientelle">
               <mat-option *ngFor="let c of clientelle" [value]="c.viewValue">
                 {{c.viewValue}}
               </mat-option>
             </mat-select>
           </mat-form-field>
          <div>
            </div>
            <button mat-raised-button matStepperPrevious color="warn">Back</button>
            &nbsp;
            <button mat-raised-button matStepperNext color="primary" (click)="next()">Next</button>
           
          </div>
        </form>
      </mat-step>
      
      <mat-step [stepControl]="contactFormGroup" [editable]="isEditable">
        <form [formGroup]="contactFormGroup">

          <div class="row col-lg-12 no-left-padding">
            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
              Please enter key contact information
          </div>
          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-6">
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
            <div class="col col-lg-6">
              <mat-form-field>
                <mat-label>Surname</mat-label>
                <input matInput formControlName="surname">
              </mat-form-field>
            </div>
          </div>
          <mat-form-field>
           <mat-label>ID Number</mat-label>
            <input matInput formControlName="idno">
          </mat-form-field>

          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-6">
              <mat-form-field>
                <mat-label>Key Contact Number</mat-label>
                <input matInput formControlName="contactnumber" placeholder="Mobile OR Office number">
              </mat-form-field>
            </div>
            <div class="col col-lg-6">

              <mat-form-field>
                <mat-label>E-mail Address</mat-label>
                <input matInput formControlName="email">
              </mat-form-field>
           </div>
           </div>
         
          <mat-form-field>
            <mat-label>Race</mat-label>
            <mat-select name="race" formControlName="race">
              <mat-option *ngFor="let r of race" [value]="r.viewValue">
                {{r.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Highest Education Passed</mat-label>
            <mat-select name="education" formControlName="education">
              <mat-option *ngFor="let e of education" [value]="e.viewValue">
                {{e.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <button mat-raised-button matStepperPrevious color="warn">Back</button>
            &nbsp;
            <button mat-raised-button matStepperNext color="primary" (click)="next()">Next</button>
          </div>
        </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="statutoryFormGroup" [editable]="isEditable">
        <form [formGroup]="statutoryFormGroup">
          <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
            Please enter statutory compliance information
          </div>
          <mat-form-field>
            <mat-label>Company Registration No</mat-label>
            <input matInput formControlName="regno">
          </mat-form-field>

          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Income Tax Number</mat-label>
                <input matInput formControlName="taxno">
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>SARS Tax PIN</mat-label>
                <input matInput formControlName="sarspin">
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>VAT Registration Number</mat-label>
                <input matInput formControlName="vat">
              </mat-form-field>
            </div>
          </div>

          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>BBBEE Certificate Level</mat-label>
                <mat-select name="bbbee" formControlName="bbbee">
                  <mat-option *ngFor="let b of bbbee" [value]="b.viewValue">
                    {{b.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Tourism Grading Council Rating</mat-label>
                <mat-select name="tourism" formControlName="tourism">
                  <mat-option *ngFor="let t of tourism" [value]="t.viewValue">
                    {{t.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>CIDB Grading</mat-label>
                  <input matInput formControlName="cidb">
              </mat-form-field>
            </div>
            </div>
         
         <mat-form-field>
            <mat-label>Industry / Profesional Body Accreditation</mat-label>
            <input matInput formControlName="industry">
          </mat-form-field>

          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>COIDA Registration Number</mat-label>
                <input matInput formControlName="coida">
              </mat-form-field>
            </div>

            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>SABS Registration</mat-label>
                <input matInput formControlName="sabs">
              </mat-form-field>
            </div>

            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>ISO Registration</mat-label>
                <input matInput formControlName="iso">
              </mat-form-field>
            </div>
          </div>

          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Health & Safety Compliance</mat-label>
                <mat-select name="health" formControlName="health">
                  <mat-option *ngFor="let c of compliant" [value]="c.viewValue">
                    {{c.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Environmental Health Compliance</mat-label>
                <mat-select name="env" formControlName="env">
                  <mat-option *ngFor="let c of compliant" [value]="c.viewValue">
                    {{c.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Town Planning Compliance</mat-label>
                <mat-select name="planning" formControlName="planning">
                  <mat-option *ngFor="let c of compliant" [value]="c.viewValue">
                    {{c.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            </div>

            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Local Tourism Organisation Affiliation</mat-label>
                  <mat-select name="tourismaffiliation" formControlName="tourismaffiliation">
                    <mat-option *ngFor="let c of compliant" [value]="c.viewValue">
                      {{c.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Business Permit / License</mat-label>
                  <input matInput formControlName="businesspermit">
                </mat-form-field>
              </div>
            
              </div>
          
            <div>
              <button mat-raised-button matStepperPrevious color="warn">Back</button>
              &nbsp;
              <button mat-raised-button matStepperNext color="primary" (click)="next()">Next</button>
            </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="experienceFormGroup" [editable]="isEditable">
        <form [formGroup]="experienceFormGroup">

        <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
            Please enter details of previous work completed
        </div>

        <mat-form-field>
          <mat-label>Client Name</mat-label>
          <input matInput formControlName="client">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Description</mat-label>
          <input matInput formControlName="description">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Value</mat-label>
          <input matInput formControlName="value">
        </mat-form-field>

        <div class="col-lg-12 no-left-padding" style="text-align: end;">
          <button mat-raised-button color="primary" (click)="addWork()" [disabled]="experienceFormGroup.controls['client'].value==null || experienceFormGroup.controls['client'].value==''">Add Work Done</button>
        </div>

          <div>
            <button mat-raised-button matStepperPrevious color="warn">Back</button>
            &nbsp;
            <button mat-raised-button matStepperNext color="primary" (click)="workNext()">Next</button>
          </div>
        </form>
        <div class="spacer10"></div>
        <div class="mat-elevation-z3">
          <table mat-table [dataSource]="workdataSource" matSort>
         <!-- ID Column -->
            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
              <td mat-cell *matCellDef="let row"> {{row.client | titlecase}} </td>
            </ng-container>
      
            <!-- Name Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                <td mat-cell *matCellDef="let row"> {{row.description | titlecase}} </td>
            </ng-container>
        
            <!-- Progress Column -->
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
              <td mat-cell *matCellDef="let row"> {{row.value}} </td>
            </ng-container>
            
            
          
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
              <!--<td mat-cell *matCellDef="let row">
                  <button type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" title="View Graduate" (click)="viewJob(row.id)"><i class="fa fa-info" aria-hidden="true"></i></button>
              </td>-->
              <td mat-cell *matCellDef="let row;">
                <button mat-fab color="warn" aria-label="Example icon button with a delete icon" (click)="removeWork(row.id)">
                  <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                </button>
              </td>
          </ng-container>
                
            <tr mat-header-row *matHeaderRowDef="displayedColumns_works"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns_works;">
            </tr>
          </table>
        
          <!--<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
        </div>
      </mat-step>

      <mat-step [stepControl]="directorFormGroup" [editable]="isEditable">
        <form [formGroup]="directorFormGroup">
          <ng-template matStepLabel>Directors</ng-template>


          <div class="row col-lg-12 no-left-padding">
            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
              Please enter Director(s) information
            </div>

            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>
              </div>
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Surname</mat-label>
                  <input matInput formControlName="surname">
                </mat-form-field>
              </div>
            </div>
        
          <mat-form-field>
            <mat-label>Nationality</mat-label>
            <mat-select name="nationality" formControlName="nationality">
              <mat-option *ngFor="let n of nationality" [value]="n.viewValue">
                {{n.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
         
          <mat-form-field>
            <mat-label>Race</mat-label>
            <mat-select name="race" formControlName="race">
              <mat-option *ngFor="let r of race" [value]="r.viewValue">
                {{r.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Gender</mat-label>
            <mat-select name="gender" formControlName="gender">
              <mat-option *ngFor="let g of gender" [value]="g.viewValue">
                {{g.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Youth</mat-label>
            <mat-select name="youth" formControlName="youth">
              <mat-option *ngFor="let y of yn" [value]="y.viewValue">
                {{y.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Shareholding (0%)</mat-label>
            <input matInput formControlName="shareholding" placeholder="Enter a value betweem 0 and 100">
          </mat-form-field>

          <div class="col-lg-12 no-left-padding" style="text-align: end;">
            <button mat-raised-button color="primary" (click)="addDirector()" [disabled]="directorFormGroup.controls['name'].value==null || directorFormGroup.controls['name'].value==''">Add Director</button>
          </div>
          
          <div>
            <button mat-raised-button matStepperPrevious color="warn">Back</button>
            &nbsp;
            <button mat-raised-button matStepperNext color="primary" (click)="directorsNext()">Next</button>
          </div>
          </div>
        </form>

        <div class="spacer10"></div>
        <div class="mat-elevation-z3">
          <table mat-table [dataSource]="dirdataSource" matSort>
         <!-- ID Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let row"> {{row.dirname | titlecase}} </td>
            </ng-container>
      
            <!-- Name Column -->
            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
                <td mat-cell *matCellDef="let row"> {{row.dirsurname | titlecase}} </td>
            </ng-container>
        
            <!-- Progress Column -->
            <ng-container matColumnDef="nationality">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nationality </th>
              <td mat-cell *matCellDef="let row"> {{row.nationality | titlecase}} </td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="race">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Race </th>
                <td mat-cell *matCellDef="let row"> {{row.dirrace | titlecase}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="gender">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
              <td mat-cell *matCellDef="let row"> {{row.gender | titlecase}} </td>
          </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="youth">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Youth </th>
              <td mat-cell *matCellDef="let row"> {{row.youth | titlecase}} </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="shareholding">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Shareholding </th>
            <td mat-cell *matCellDef="let row"> {{row.shareholding}} </td>
        </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
              <!--<td mat-cell *matCellDef="let row">
                  <button type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" title="View Graduate" (click)="viewJob(row.id)"><i class="fa fa-info" aria-hidden="true"></i></button>
              </td>-->
              <td mat-cell *matCellDef="let row">
                <button mat-fab color="warn" aria-label="Example icon button with a delete icon" (click)="removeDirector(row.id)">
                  <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                </button>
              </td>
          </ng-container>
                
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
        
          <!--<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
        </div>

      </mat-step>

      <mat-step [stepControl]="breakdownFormGroup" [editable]="isEditable">
        <form [formGroup]="breakdownFormGroup">

          <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
            Please enter Directors / Management / Staff breakdown
           </div>

          <div class="mat-table2">
            <div class="mat-header-row2 full-text">
              <div class="mat-header-cell2"></div>
              <div class="mat-header-cell2">Black Male</div>
              <div class="mat-header-cell2">Black Female</div>
              <div class="mat-header-cell2">Total</div>
              <div class="mat-header-cell2">White Male</div>
              <div class="mat-header-cell2">White Female</div>
              <div class="mat-header-cell2">Total</div>
              <div class="mat-header-cell2">Grand Totals</div>
            </div>
            <div class="mat-header-row3 short-text row col-lg-12">
              <div class="mat-header-cell3 col col-lg-*" style="padding-right: 50px;"></div>
              <div class="mat-header-cell3 col col-lg-* small-padding-right">BM</div>
              <div class="mat-header-cell3 col col-lg-* small-padding-right">BF</div>
              <div class="mat-header-cell3 col col-lg-* small-padding-right2">T</div>
              <div class="mat-header-cell3 col col-lg-* small-padding-right">WM</div>
              <div class="mat-header-cell3 col col-lg-* small-padding-right2">WF</div>
              <div class="mat-header-cell3 col col-lg-* small-padding-right2">T</div>
              <div class="mat-header-cell3 col col-lg-* small-padding-right">G</div>
            </div>

            <div class="mat-row2">
              <div class="mat-cell2 full-text" style="padding-right: 10px;">
                Shareholding Ownership (%)
              </div>
              <div class="mat-cell2 short-text" style="padding-right: 0px;">
                Share(%)
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="sharebm" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="sharebf" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{sharebtot}}</span>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="sharewm" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="sharewf" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{sharewtot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{sharetot}}</span>
              </div>
            </div>

            <div class="mat-row2">
              <div class="mat-cell2 full-text" style="padding-right: 10px;">
                Senior Management
              </div>
              <div class="mat-cell2 short-text" style="padding-right: 0px;">
                Mngt
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="seniorbm" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="seniorbf" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{seniorbtot}}</span>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="seniorwm" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="seniorwf" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{seniorwtot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{seniortot}}</span>
              </div>
            </div>

            <div class="mat-row2">
              <div class="mat-cell2 full-text" style="padding-right: 10px;">
                Non-Managerial Staff
              </div>
              <div class="mat-cell2 short-text" style="padding-right: 0px;">
                Staff
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="staffbm" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="staffbf" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{staffbtot}}</span>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="staffwm" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="staffwf" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{staffwtot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{stafftot}}</span>
              </div>
            </div>

            <div class="mat-row2">
              <div class="mat-cell2 full-text" style="padding-right: 10px;">
                Temporary Staff
              </div>
              <div class="mat-cell2 short-text" style="padding-right: 0px;">
                Temp
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="tempbm" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="tempbf" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{tempbtot}}</span>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="tempwm" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <mat-form-field style="width: 100px;">
                  <input matInput formControlName="tempwf" type="number" (change)="calcBreakdown()">
                </mat-form-field>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{tempwtot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{temptot}}</span>
              </div>
            </div>

            <div class="mat-row2">
              <div class="mat-cell2 full-text" style="padding-right: 10px;">
                Grand Totals
              </div>
              <div class="mat-cell2 short-text" style="padding-right: 0px;">
               Tot
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{bmtot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{bftot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{btot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{wmtot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{wftot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{wtot}}</span>
              </div>
              <div class="mat-cell2">
                <span style="font-weight: 700; font-size: 20px;">{{grandtot}}</span>
              </div>
            </div>

          </div>

          <div class="short-text">
            <div class="spacer10"></div>
            <span>BM=Black Male | BF=Black Female | T=Total | WM=White Male | WF=White Female | G=Grand Total | Mngt=Management</span>
          </div>
          <div class="spacer20"></div>
          <div>
            <button mat-raised-button matStepperPrevious color="warn">Back</button>
            &nbsp;
            <button mat-raised-button matStepperNext color="primary" (click)="next()">Next</button>
          </div>
        </form>

        
        
       
        
          <!--<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->

        

      </mat-step>

      

      <mat-step [stepControl]="expertiseFormGroup" [editable]="isEditable">
        <form [formGroup]="expertiseFormGroup">
          <ng-template matStepLabel>Business Expertise</ng-template>

          <div class="row col-lg-12 no-left-padding">

            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
              Please enter business expertise information
          </div>
          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Financial Management</mat-label>
                <mat-select name="fin" formControlName="fin">
                  <mat-option *ngFor="let r of rating" [value]="r.viewValue">
                    {{r.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Sales Management</mat-label>
                <mat-select name="sales" formControlName="sales">
                  <mat-option *ngFor="let r of rating" [value]="r.viewValue">
                    {{r.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Business Management</mat-label>
                <mat-select name="business" formControlName="business">
                  <mat-option *ngFor="let r of rating" [value]="r.viewValue">
                    {{r.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
         
          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Human Resource Issues</mat-label>
                <mat-select name="hr" formControlName="hr">
                  <mat-option *ngFor="let r of rating" [value]="r.viewValue">
                    {{r.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Tax Issues</mat-label>
                <mat-select name="tax" formControlName="tax">
                  <mat-option *ngFor="let r of rating" [value]="r.viewValue">
                    {{r.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Business Strategy</mat-label>
                <mat-select name="strat" formControlName="strat">
                  <mat-option *ngFor="let r of rating" [value]="r.viewValue">
                    {{r.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            </div>

            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Marketing</mat-label>
                  <mat-select name="marketing" formControlName="marketing">
                    <mat-option *ngFor="let r of rating" [value]="r.viewValue">
                      {{r.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Presenting Skills</mat-label>
                  <mat-select name="presenting" formControlName="presenting">
                    <mat-option *ngFor="let r of rating" [value]="r.viewValue">
                      {{r.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Tendering Skills</mat-label>
                  <mat-select name="tendering" formControlName="tendering">
                    <mat-option *ngFor="let r of rating" [value]="r.viewValue">
                      {{r.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              </div>
          
              <div>
                <button mat-raised-button matStepperPrevious color="warn">Back</button>
                &nbsp;
                <button mat-raised-button matStepperNext color="primary" (click)="next()">Next</button>
              </div>
            </div>
        </form>
      </mat-step>

      <div *ngIf="renderChecks">
      <mat-step [stepControl]="expertiseFormGroup" [editable]="isEditable">
        <!--<form [formGroup]="expertiseFormGroup">-->

        <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
            Please enter required service improvement
        </div>

       

          <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
              {{fin[0].expertise}}
          </div>
            <div *ngFor="let f of fin; let i = index;">
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-10" style="background-color: #1D909D; padding: 10px; color: #fff; font-size: 16px;">
                  {{f.description}}
                </div>
                <div class="col col-lg-2" style="background-color: #ececec; padding: 10px; color: #fff; font-size: 66px;">
                  <mat-checkbox 
                    class="example-margin"
                    (change)="setImprov($event.checked, f.description, f.expertise, f.id)"
                    name="chk1{{i}}"
                    id="chk1{{i}}"
                  >
                  </mat-checkbox>
                </div>
                <div class="spacer5"></div>
                </div>
            </div>

            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
              {{hr[0].expertise}}
            </div>
            <div *ngFor="let h of hr; let i = index;">
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-10" style="background-color: #1D909D; padding: 10px; color: #fff; font-size: 16px;">
                  {{h.description}}
                </div>
                <div class="col col-lg-2" style="background-color: #ececec; padding: 10px; color: #fff; font-size: 66px;">
                  <mat-checkbox 
                    class="example-margin"
                    (change)="setImprov($event.checked, h.description, h.expertise, h.id)"
                    name="chk2{{i}}"
                    id="chk2{{i}}"
                    >

                  </mat-checkbox>
                </div>
                <div class="spacer5"></div>
                </div>
            </div>

            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
              {{productivity[0].expertise}}
            </div>
            <div *ngFor="let p of productivity; let i = index;">
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-10" style="background-color: #1D909D; padding: 10px; color: #fff; font-size: 16px;">
                  {{p.description}}
                </div>
                <div class="col col-lg-2" style="background-color: #ececec; padding: 10px; color: #fff; font-size: 66px;">
                  <mat-checkbox 
                    class="example-margin"
                    (change)="setImprov($event.checked, p.description, p.expertise, p.id)"
                    name="chk3{{i}}"
                    id="chk3{{i}}"
                    >
                  </mat-checkbox>
                </div>
                <div class="spacer5"></div>
                </div>
            </div>

            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
              {{marketing[0].expertise}}
            </div>
            <div *ngFor="let m of marketing; let i = index;">
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-10" style="background-color: #1D909D; padding: 10px; color: #fff; font-size: 16px;">
                  {{m.description}}
                </div>
                <div class="col col-lg-2" style="background-color: #ececec; padding: 10px; color: #fff; font-size: 66px;">
                  <mat-checkbox 
                    class="example-margin"
                    (change)="setImprov($event.checked, m.description, m.expertise, m.id)"
                    name="chk4{{i}}"
                    id="chk4{{i}}"
                    >
                  </mat-checkbox>
                </div>
                <div class="spacer5"></div>
                </div>
            </div>

            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
              {{quality[0].expertise}}
            </div>
            <div *ngFor="let q of quality; let i = index;">
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-10" style="background-color: #1D909D; padding: 10px; color: #fff; font-size: 16px;">
                  {{q.description}}
                </div>
                <div class="col col-lg-2" style="background-color: #ececec; padding: 10px; color: #fff; font-size: 66px;">
                  <mat-checkbox 
                    class="example-margin"
                    (change)="setImprov($event.checked, q.description, q.expertise, q.id)"
                    name="chk5{{i}}"
                    id="chk5{{i}}"
                    >
                  </mat-checkbox>
                </div>
                <div class="spacer5"></div>
                </div>
            </div>

            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
              {{crm[0].expertise}}
            </div>
            <div *ngFor="let c of crm; let i = index;">
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-10" style="background-color: #1D909D; padding: 10px; color: #fff; font-size: 16px;">
                  {{c.description}}
                </div>
                <div class="col col-lg-2" style="background-color: #ececec; padding: 10px; color: #fff; font-size: 66px;">
                  <mat-checkbox 
                    class="example-margin"
                    (change)="setImprov($event.checked, c.description, c.expertise, c.id)"
                    name="chk6{{i}}"
                    id="chk6{{i}}"
                    >
                  </mat-checkbox>
                </div>
                <div class="spacer5"></div>
                </div>
            </div>
              
            <div class="spacer10"></div>
            <div>
              <button mat-raised-button matStepperPrevious color="warn">Back</button>
              &nbsp;
              <button mat-raised-button matStepperNext color="primary" (click)="next()">Next</button>
             
            </div>
        <!--</form>-->
      </mat-step>
      </div>

      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <div class="col col-lg-12 no-left-padding center" style="padding: 10px; color: #263A3D; font-size: 32px; text-align: center;">
          Ready to Submit?
        </div>
        <hr />

        <div *ngIf="business">
         <form >
          <div class="row col-lg-12" >
            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
             Basic Business Information
          </div>
            <div class="row col-lg-12">
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput value="{{business.name}}" readonly>
              </mat-form-field>
            </div>
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Website Address</mat-label>
                  <input matInput value="{{business.website}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>E-mail Address</mat-label>
                  <input matInput  value="{{business.email}}" readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Telephone Number</mat-label>
                  <input matInput  value="{{business.tel}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Fax Number</mat-label>
                  <input matInput value="{{business.fax}}" readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="spacer30"></div>
            <div class="row col-lg-12 no-left-padding">
              <div class="row col-lg-12">
                <mat-label style="color: green; padding-left: 15px; font-weight: 500;">Physical Address</mat-label>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Street Number</mat-label>
                  <input matInput value="{{business.rstreet}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Suburb</mat-label>
                  <input matInput value="{{business.rsuburb}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Town / City</mat-label>
                  <input matInput value="{{business.rcity}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Code</mat-label>
                  <input matInput value="{{business.rcode}}" readonly>
                </mat-form-field>
              </div>
            </div>

            <div class="spacer30"></div>
            <div class="row col-lg-12 no-left-padding">
              <div class="row col-lg-12">
                <mat-label style="color: green; padding-left: 15px; font-weight: 500;">Postal Address</mat-label>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Box / Street Number</mat-label>
                  <input matInput value="{{business.pstreet}}" readonly>
                </mat-form-field>
              </div>
              
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Suburb</mat-label>
                  <input matInput value="{{business.psuburb}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Town / City</mat-label>
                  <input matInput value="{{business.ptown}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-3">
                <mat-form-field>
                  <mat-label>Code</mat-label>
                  <input matInput value="{{business.pcode}}" readonly>
                </mat-form-field>
              </div>
              
            <div class="col col-lg-12">
            <mat-form-field>
              <mat-label>Business Location</mat-label>
              <input matInput value="{{business.location}}" readonly>
            </mat-form-field>
            </div>
            <div class="col col-lg-12">
            <mat-form-field>
              <mat-label>Stage of Business</mat-label>
              <input matInput value="{{business.stage}}" readonly>
            </mat-form-field>
            </div>
            <div class="col col-lg-12">
            <mat-form-field>
              <mat-label>Type of Industry</mat-label>
              <input matInput value="{{business.industry}}" readonly>
            </mat-form-field>
            </div>
            <div class="col col-lg-12">
            <mat-form-field>
              <mat-label>Sub Industry</mat-label>
              <input matInput value="{{business.subindustry}}" readonly>
            </mat-form-field>
            </div>
            <div class="col col-lg-12">
            <mat-form-field>
              <mat-label>Current Trading Location</mat-label>
              <input matInput value="{{business.tradinglocation}}" readonly>
            </mat-form-field>
            </div>
            <div class="col col-lg-12">
            <mat-form-field>
              <mat-label>Current Turnover</mat-label>
              <input matInput value="{{business.turnover}}" readonly>
            </mat-form-field>
            </div>
            <div class="col col-lg-12">
            <mat-form-field>
              <mat-label>Business Service / Production</mat-label>
              <input matInput value="{{business.service}}" readonly>
            </mat-form-field>
            </div>
            <div class="col col-lg-12">
            <mat-form-field>
              <mat-label>Business Vision</mat-label>
              <input matInput value="{{business.vision}}">
             </mat-form-field>
             </div>
             <div class="col col-lg-12">
             <mat-form-field>
               <mat-label>Where do you see your business in 5 years?</mat-label>
               <input matInput value="{{business.fiveyears}}" readonly>
             </mat-form-field>
             </div>
             <div class="col col-lg-12">
             <mat-form-field>
               <mat-label>Clientelle</mat-label>
               <input matInput value="{{business.clientelle}}" readonly>
             </mat-form-field>
             </div>
            </div>
            

             <div class="row col-lg-12">
              <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
               Key contact information
            </div>
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput value="{{business.contactname}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Surname</mat-label>
                  <input matInput value="{{business.surname}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-12">
                <mat-form-field>
                <mat-label>ID Number</mat-label>
                <input matInput value="{{business.idno}}" readonly>
                </mat-form-field>
              </div>
  
              <div class="row col-lg-12">
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>Key Contact Number</mat-label>
                    <input matInput value="{{business.contactnumber}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-6">
    
                  <mat-form-field>
                    <mat-label>E-mail Address</mat-label>
                    <input matInput value="{{business.contactemail}}" readonly>
                  </mat-form-field>
              </div>
              </div>
              <div class="col col-lg-12">
                <mat-form-field>
                  <mat-label>Race</mat-label>
                  <input matInput value="{{business.race}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-12">
                <mat-form-field>
                  <mat-label>Highest Education Passed</mat-label>
                  <input matInput value="{{business.education}}" readonly>
                </mat-form-field>
              </div>
           
             </div>
             </div>
            
          <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
           Statutory compliance information
          </div>
          <mat-form-field>
            <mat-label>Company Registration No</mat-label>
            <input matInput value="{{business.regno}}" readonly>
          </mat-form-field>

          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Income Tax Number</mat-label>
                <input matInput value="{{business.taxno}}" readonly>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>SARS Tax PIN</mat-label>
                <input matInput value="{{business.sarspin}}" readonly>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>VAT Registration Number</mat-label>
                <input matInput value="{{business.vat}}" readonly>
              </mat-form-field>
            </div>
          </div>

          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>BBBEE Certificate Level</mat-label>
                <input matInput value="{{business.bbbee}}" readonly>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Tourism Grading Council Rating</mat-label>
                <input matInput value="{{business.tourism}}" readonly>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>CIDB Grading</mat-label>
                <input matInput value="{{business.cidb}}" readonly>
              </mat-form-field>
            </div>
            </div>
         
         <mat-form-field>
            <mat-label>Industry / Profesional Body Accreditation</mat-label>
            <input matInput value="{{business.statindustry}}" readonly>
          </mat-form-field>

          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>COIDA Registration Number</mat-label>
                <input matInput value="{{business.coida}}" readonly>
              </mat-form-field>
            </div>

            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>SABS Registration</mat-label>
                <input matInput value="{{business.sabs}}" readonly>
              </mat-form-field>
            </div>

            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>ISO Registration</mat-label>
                <input matInput value="{{business.iso}}" readonly>
              </mat-form-field>
            </div>
          </div>

          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Health & Safety Compliance</mat-label>
                <input matInput value="{{business.health}}" readonly>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Environmental Health Compliance</mat-label>
                <input matInput value="{{business.env}}" readonly>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <mat-form-field>
                <mat-label>Town Planning Compliance</mat-label>
                <input matInput value="{{business.planning}}" readonly>
              </mat-form-field>
            </div>
            </div>

            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Local Tourism Organisation Affiliation</mat-label>
                  <input matInput value="{{business.tourismaffiliation}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-6">
                <mat-form-field>
                  <mat-label>Business Permit / License</mat-label>
                  <input matInput value="{{business.businesspermit}}" readonly>
                </mat-form-field>
              </div>
            
              </div>

            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
              Previous work completed
            </div>

            <div class="mat-elevation-z3">
              <table mat-table [dataSource]="workdataSource" matSort>
             <!-- ID Column -->
                <ng-container matColumnDef="client">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                  <td mat-cell *matCellDef="let row"> {{row.client | titlecase}} </td>
                </ng-container>
          
                <!-- Name Column -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let row"> {{row.description | titlecase}} </td>
                </ng-container>
            
                <!-- Progress Column -->
                <ng-container matColumnDef="value">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
                  <td mat-cell *matCellDef="let row"> {{row.value}} </td>
                </ng-container>
                
                
              
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                  <!--<td mat-cell *matCellDef="let row">
                      <button type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" title="View Graduate" (click)="viewJob(row.id)"><i class="fa fa-info" aria-hidden="true"></i></button>
                  </td>-->
                  <td mat-cell *matCellDef="let row">
                    <!--<button style="background-color:#4285F4" mat-mini-fab type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" ngbTooltip="View Job" ><i class="fa fa-info" aria-hidden="true"></i></button>-->
                  </td>
              </ng-container>
                    
                <tr mat-header-row *matHeaderRowDef="displayedColumns_works"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns_works;">
                </tr>
              </table>
            
              <!--<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
            </div>

            <div class="spacer10"></div>
            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
              Director(s) information
            </div>

            <div class="mat-elevation-z3">
              <table mat-table [dataSource]="dirdataSource" matSort>
             <!-- ID Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                  <td mat-cell *matCellDef="let row"> {{row.dirname | titlecase}} </td>
                </ng-container>
          
                <!-- Name Column -->
                <ng-container matColumnDef="surname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
                    <td mat-cell *matCellDef="let row"> {{row.dirsurname | titlecase}} </td>
                </ng-container>
            
                <!-- Progress Column -->
                <ng-container matColumnDef="nationality">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nationality </th>
                  <td mat-cell *matCellDef="let row"> {{row.nationality | titlecase}} </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="race">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Race </th>
                    <td mat-cell *matCellDef="let row"> {{row.dirrace | titlecase}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="gender">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                  <td mat-cell *matCellDef="let row"> {{row.gender | titlecase}} </td>
              </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="youth">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Youth </th>
                  <td mat-cell *matCellDef="let row"> {{row.youth | titlecase}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="shareholding">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Shareholding </th>
                <td mat-cell *matCellDef="let row"> {{row.shareholding}} </td>
            </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                  <td mat-cell *matCellDef="let row">
                    <!--<button style="background-color:#4285F4" mat-mini-fab type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" ngbTooltip="View Job" ><i class="fa fa-info" aria-hidden="true"></i></button>-->
                  </td>
              </ng-container>
                    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
              </table>
            
              <!--<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
            </div>

            <div class="spacer10"></div>
          

              <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
                Business expertise information
              </div>
              <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Financial Management</mat-label>
                  <input matInput value="{{business.fin}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Sales Management</mat-label>
                  <input matInput value="{{business.sales}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Business Management</mat-label>
                  <input matInput value="{{business.business}}" readonly>
                </mat-form-field>
              </div>
              </div>
           
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Human Resource Issues</mat-label>
                  <input matInput value="{{business.hr}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Tax Issues</mat-label>
                  <input matInput value="{{business.strat}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Business Strategy</mat-label>
                  <input matInput value="{{business.tax}}" readonly>
                </mat-form-field>
              </div>
              </div>
  
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Marketing</mat-label>
                    <input matInput value="{{business.marketing}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Presenting Skills</mat-label>
                    <input matInput value="{{business.presenting}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Tendering Skills</mat-label>
                    <input matInput value="{{business.tendering}}" readonly>
                  </mat-form-field>
                </div>
                </div>
           
                

               
            
          </div>
        
          
        </form>

        <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
          Required service improvement
        </div>

        <div *ngFor="let f of improvements">
          <div class="row col-lg-12 no-left-padding">
            <div class="col col-lg-12" style="background-color: #ececec; padding: 10px; color: #302d2d; font-size: 16px;">
              {{f.description}}
            </div>
            
            <div class="spacer5"></div>
            </div>
        </div>

        </div>
       
        <div>
          <button mat-raised-button matStepperPrevious color="warn">Back</button>
          &nbsp;
          <button mat-raised-button matStepperNext color="accent"(click)="stepper.reset()">Reset</button>
          &nbsp;
          <button mat-raised-button matStepperNext color="primary" (click)="submit()">Submit</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
    
   
    <!--<pre>{{business | json}}</pre>
    <pre>{{directors | json}}</pre>
    <pre>{{works | json}}</pre>
    <pre>{{improvements | json}}</pre>-->
</div>

 <!--Adddit Education Modal-->
 <ng-template #viewbusinessModal let-modal>
  <div class="modal-header">
      <h3 class="modal-title fade-1" id="modal-basic-title">Business Captured Sucessfully</h3>
        <button type="button" class="close" aria-label="Close" (click)="valaModal()">
          <span aria-hidden="true">&times;</span>
        </button>
  </div>
  <div class="modal-body">

 
          <div *ngIf="_business">
           <form >
            <div class="row col-lg-12" >
              <div class="row col-lg-6 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
               Basic Business Information | REF : {{_business.id}}
            </div>
            <div class="row col-lg-6 no-left-padding">
              <button mat-raised-button color="warn" (click)="goHome()">Close</button>
              &nbsp;
              <button mat-raised-button color="primary" (click)="captureAnother()">Capture Another</button>
            </div>
              <div class="row col-lg-12">
                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput value="{{_business.name}}" readonly>
                </mat-form-field>
              </div>
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>Website Address</mat-label>
                    <input matInput value="{{_business.website}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>E-mail Address</mat-label>
                    <input matInput  value="{{_business.email}}" readonly>
                  </mat-form-field>
                </div>
              </div>
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>Telephone Number</mat-label>
                    <input matInput  value="{{_business.tel}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>Fax Number</mat-label>
                    <input matInput value="{{_business.fax}}" readonly>
                  </mat-form-field>
                </div>
              </div>
              <div class="spacer30"></div>
              <div class="row col-lg-12 no-left-padding">
                <div class="row col-lg-12">
                  <mat-label style="color: green; padding-left: 15px; font-weight: 500;">Physical Address</mat-label>
                </div>
                <div class="col col-lg-3">
                  <mat-form-field>
                    <mat-label>Street Number</mat-label>
                    <input matInput value="{{_business.rstreet}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-3">
                  <mat-form-field>
                    <mat-label>Suburb</mat-label>
                    <input matInput value="{{_business.rsuburb}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-3">
                  <mat-form-field>
                    <mat-label>Town / City</mat-label>
                    <input matInput value="{{_business.rcity}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-3">
                  <mat-form-field>
                    <mat-label>Code</mat-label>
                    <input matInput value="{{_business.rcode}}" readonly>
                  </mat-form-field>
                </div>
              </div>
  
              <div class="spacer30"></div>
              <div class="row col-lg-12 no-left-padding">
                <div class="row col-lg-12">
                  <mat-label style="color: green; padding-left: 15px; font-weight: 500;">Postal Address</mat-label>
                </div>
                <div class="col col-lg-3">
                  <mat-form-field>
                    <mat-label>Box / Street Number</mat-label>
                    <input matInput value="{{_business.pstreet}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-3">
                  <mat-form-field>
                    <mat-label>Suburb</mat-label>
                    <input matInput value="{{_business.psuburb}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-3">
                  <mat-form-field>
                    <mat-label>Town / City</mat-label>
                    <input matInput value="{{_business.ptown}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-3">
                  <mat-form-field>
                    <mat-label>Code</mat-label>
                    <input matInput value="{{_business.pcode}}" readonly>
                  </mat-form-field>
                </div>
              </div>
  
              <mat-form-field>
                <mat-label>Business Location</mat-label>
                <input matInput value="{{_business.location}}" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Stage of Business</mat-label>
                <input matInput value="{{_business.stage}}" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Type of Industry</mat-label>
                <input matInput value="{{_business.industry}}" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Sub Industry</mat-label>
                <input matInput value="{{_business.subindustry}}" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Current Trading Location</mat-label>
                <input matInput value="{{_business.tradinglocation}}" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Current Turnover</mat-label>
                <input matInput value="{{_business.turnover}}" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Business Service / Production</mat-label>
                <input matInput value="{{_business.service}}" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Business Vision</mat-label>
                <input matInput value="{{_business.vision}}">
               </mat-form-field>
               <mat-form-field>
                 <mat-label>Where do you see your business in 5 years?</mat-label>
                 <input matInput value="{{_business.fiveyears}}" readonly>
               </mat-form-field>
               <mat-form-field>
                 <mat-label>Clientelle</mat-label>
                 <input matInput value="{{_business.clientelle}}" readonly>
               </mat-form-field>
  
              
              <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
                 Key contact information
              </div>
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput value="{{_business.contactname}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>Surname</mat-label>
                    <input matInput value="{{_business.surname}}" readonly>
                  </mat-form-field>
                </div>
              </div>
    
              <mat-form-field>
               <mat-label>ID Number</mat-label>
               <input matInput value="{{_business.idno}}" readonly>
              </mat-form-field>
    
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>Key Contact Number</mat-label>
                    <input matInput value="{{_business.contactnumber}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-6">
    
                  <mat-form-field>
                    <mat-label>E-mail Address</mat-label>
                    <input matInput value="{{_business.contactemail}}" readonly>
                  </mat-form-field>
               </div>
               </div>
             
              <mat-form-field>
                <mat-label>Race</mat-label>
                <input matInput value="{{_business.race}}" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Highest Education Passed</mat-label>
                <input matInput value="{{_business.education}}" readonly>
              </mat-form-field>
              
              
            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
             Statutory compliance information
            </div>
            <mat-form-field>
              <mat-label>Company Registration No</mat-label>
              <input matInput value="{{_business.regno}}" readonly>
            </mat-form-field>
  
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Income Tax Number</mat-label>
                  <input matInput value="{{_business.taxno}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>SARS Tax PIN</mat-label>
                  <input matInput value="{{_business.sarspin}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>VAT Registration Number</mat-label>
                  <input matInput value="{{_business.vat}}" readonly>
                </mat-form-field>
              </div>
            </div>
  
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>BBBEE Certificate Level</mat-label>
                  <input matInput value="{{_business.bbbee}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Tourism Grading Council Rating</mat-label>
                  <input matInput value="{{business.tourism}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>CIDB Grading</mat-label>
                  <input matInput value="{{_business.cidb}}" readonly>
                </mat-form-field>
              </div>
              </div>
           
           <mat-form-field>
              <mat-label>Industry / Profesional Body Accreditation</mat-label>
              <input matInput value="{{_business.statindustry}}" readonly>
            </mat-form-field>
  
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>COIDA Registration Number</mat-label>
                  <input matInput value="{{_business.coida}}" readonly>
                </mat-form-field>
              </div>
  
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>SABS Registration</mat-label>
                  <input matInput value="{{_business.sabs}}" readonly>
                </mat-form-field>
              </div>
  
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>ISO Registration</mat-label>
                  <input matInput value="{{_business.iso}}" readonly>
                </mat-form-field>
              </div>
            </div>
  
            <div class="row col-lg-12 no-left-padding">
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Health & Safety Compliance</mat-label>
                  <input matInput value="{{_business.health}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Environmental Health Compliance</mat-label>
                  <input matInput value="{{_business.env}}" readonly>
                </mat-form-field>
              </div>
              <div class="col col-lg-4">
                <mat-form-field>
                  <mat-label>Town Planning Compliance</mat-label>
                  <input matInput value="{{_business.planning}}" readonly>
                </mat-form-field>
              </div>
              </div>
  
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>Local Tourism Organisation Affiliation</mat-label>
                    <input matInput value="{{_business.tourismaffiliation}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-6">
                  <mat-form-field>
                    <mat-label>Business Permit / License</mat-label>
                    <input matInput value="{{_business.businesspermit}}" readonly>
                  </mat-form-field>
                </div>
              
                </div>
  
              <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
                Previous work completed
              </div>
  
              <div class="mat-elevation-z3">
                <table mat-table [dataSource]="_workdataSource" matSort>
                  <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                    <td mat-cell *matCellDef="let row"> {{row.client | titlecase}} </td>
                  </ng-container>
            
                  <ng-container matColumnDef="description">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                      <td mat-cell *matCellDef="let row"> {{row.description | titlecase}} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
                    <td mat-cell *matCellDef="let row"> {{row.value}} </td>
                  </ng-container>
                  
                  
                
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                    
                    <td mat-cell *matCellDef="let row">
                    </td>
                </ng-container>
                      
                  <tr mat-header-row *matHeaderRowDef="displayedColumns_works"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns_works;">
                  </tr>
                </table>
              
                <!--<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
              </div>
  
              <div class="spacer10"></div>
              <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
                Director(s) information
              </div>
  
              <div class="mat-elevation-z3">
                <table mat-table [dataSource]="_dirdataSource" matSort>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.dirname | titlecase}} </td>
                  </ng-container>
            
                  <ng-container matColumnDef="surname">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
                      <td mat-cell *matCellDef="let row"> {{row.dirsurname | titlecase}} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="nationality">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nationality </th>
                    <td mat-cell *matCellDef="let row"> {{row.nationality | titlecase}} </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="race">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Race </th>
                      <td mat-cell *matCellDef="let row"> {{row.dirrace | titlecase}} </td>
                  </ng-container>
                  <ng-container matColumnDef="gender">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                    <td mat-cell *matCellDef="let row"> {{row.gender | titlecase}} </td>
                </ng-container>
                  <ng-container matColumnDef="youth">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Youth </th>
                    <td mat-cell *matCellDef="let row"> {{row.youth | titlecase}} </td>
                </ng-container>
                <ng-container matColumnDef="shareholding">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Shareholding </th>
                  <td mat-cell *matCellDef="let row"> {{row.shareholding}} </td>
              </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                   
                    <td mat-cell *matCellDef="let row">
                    </td>
                </ng-container>
                      
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                  </tr>
                </table>
              
              </div>
  
              <div class="spacer10"></div>
            
  
              <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
                 Business expertise information
              </div>
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Financial Management</mat-label>
                    <input matInput value="{{_business.fin}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Sales Management</mat-label>
                    <input matInput value="{{_business.sales}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Business Management</mat-label>
                    <input matInput value="{{_business.business}}" readonly>
                  </mat-form-field>
                </div>
                </div>
             
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Human Resource Issues</mat-label>
                    <input matInput value="{{_business.hr}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Tax Issues</mat-label>
                    <input matInput value="{{_business.tax}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Business Strategy</mat-label>
                    <input matInput value="{{_business.strat}}" readonly>
                  </mat-form-field>
                </div>
                </div>
    
                <div class="row col-lg-12 no-left-padding">
                  <div class="col col-lg-4">
                    <mat-form-field>
                      <mat-label>Marketing</mat-label>
                      <input matInput value="{{_business.marketing}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-4">
                    <mat-form-field>
                      <mat-label>Presenting Skills</mat-label>
                      <input matInput value="{{_business.presenting}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-4">
                    <mat-form-field>
                      <mat-label>Tendering Skills</mat-label>
                      <input matInput value="{{_business.tendering}}" readonly>
                    </mat-form-field>
                  </div>
                  </div>
             
                  
  
                 
              
            </div>
          
            
          </form>
  
          <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
            Required service improvement
          </div>
  
          <div *ngFor="let f of _improvements">
            <div class="row col-lg-12">
              <div class="col col-lg-12 no-left-padding" style="background-color: #ececec; padding: 10px; color: #302d2d; font-size: 16px;">
                {{f.description}}
              </div>
              <div class="spacer5"></div>
            </div>
          </div>
  
          </div>
         
          <div>
            <button mat-raised-button color="warn" (click)="goHome()">Close</button>
            &nbsp;
            <button mat-raised-button color="primary" (click)="captureAnother()">Capture Another</button>
          </div>
    
  </div>
 
</ng-template>

<ng-template #submitting let-modal>
    
  <div class="modal-body center">
      <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
      <div class="spacer20"></div>
      <p class="processing">Submitting...</p>
      <p class="progressing">{{_submitting}}</p>
  </div>
  <!--<div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
    </div>-->
 
</ng-template>
